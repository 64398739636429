/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Control, Controller, RegisterOptions } from "react-hook-form";
import React, { ReactElement, useEffect, useRef, useState } from "react";

interface FieldProps {
  control: Control<any>;
  defaultChecked?: boolean;
  disabled?: boolean;
  name: string;
  fieldOptions?: Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  label?: string;
  onChange?(e: boolean): void;
  readOnly?: boolean;
  required?: boolean;
}

const Switch2 = ({
  control,
  defaultChecked,
  disabled,
  fieldOptions,
  label,
  name,
  onChange: customOnChange,
  readOnly,
  required,
}: FieldProps): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});
  const switchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (defaultChecked) {
      switchRef.current?.toggleAttribute("checked");
    }
  }, [defaultChecked]);

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
  }, [required, registerOptions]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div className={`${error ? "has-error" : ""}`}>
            <div className="flex space-x-3">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  checked={value}
                  className="sr-only peer"
                  onChange={(e) => {
                    onChange(e.target.checked);

                    if(customOnChange){
                      customOnChange(e.target.checked);
                    }
                  }}
                  ref={switchRef}
                  type="checkbox"
                />
                <div
                  className={`rounded-full bg-gray-200 w-11 h-6 peer peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-sky-100 dark:peer-focus:ring-sky-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:bg-gray-700 dark:border-gray-600 ${
                    disabled || readOnly ? "peer-checked:bg-sky-200" : "peer-checked:bg-sky-500"
                  }`}
                />
                {label && <label>{label}</label>}
              </label>
            </div>
            <p>{error && error.message?.toString()}</p>
          </div>
        );
      }}
      rules={registerOptions}
    />
  );
};

export default Switch2;
