/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { axiosCommon } from "../util/axiosSetup";
import { PendingClinicRegistrationResponse } from "../models/clinic/PendingClinicRegistrationResponse";
import { ClinicRegistrationRequest } from "../models/clinic/ClinicRegistrationRequest";
import { ContactsRequest } from "../models/clinic/ContactsRequest";
import { PhoneConfirmationRequest } from "../models/contact/PhoneConfirmationRequest";
import { CreditCardRequest } from "../models/clinic/CreditCardRequest";
import { AddressRequest } from "../models/contact/AddressRequest";
import { LocationRequest } from "../models/clinic/LocationRequest";

export default class ClinicRegistrationApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getPendingRegistration(): ApiPromise<PendingClinicRegistrationResponse> {
    const url = `${this.baseUrl}/clinic-registration`;
    return axiosCommon.get(url);
  }

  static startRegistration(
    request: ClinicRegistrationRequest
  ): ApiPromise<PendingClinicRegistrationResponse> {
    const url = `${this.baseUrl}/clinic-registration`;
    return axiosCommon.post(url, request);
  }

  static addBasicInformation(
    id: string,
    request: ClinicRegistrationRequest
  ): ApiPromise<PendingClinicRegistrationResponse> {
    const url = `${this.baseUrl}/clinic-registration/${id}/basic-information`;
    return axiosCommon.post(url, request);
  }

  static addContacts(
    id: string,
    request: ContactsRequest
  ): ApiPromise<PendingClinicRegistrationResponse> {
    const url = `${this.baseUrl}/clinic-registration/${id}/contacts`;
    return axiosCommon.post(url, request);
  }

  static confirmPhone(
    id: string,
    request: PhoneConfirmationRequest
  ): ApiPromise<PendingClinicRegistrationResponse> {
    const url = `${this.baseUrl}/clinic-registration/${id}/phone-confirmation`;
    return axiosCommon.post(url, request);
  }

  static sendPhoneConfirmation(
    id: string
  ): ApiPromise<PendingClinicRegistrationResponse> {
    const url = `${this.baseUrl}/clinic-registration/${id}/send-confirmation-sms`;
    return axiosCommon.post(url);
  }

  static addAddress(
    id: string,
    request: AddressRequest
  ): ApiPromise<PendingClinicRegistrationResponse> {
    const url = `${this.baseUrl}/clinic-registration/${id}/address`;
    return axiosCommon.post(url, request);
  }

  static addLocation(
    id: string,
    request: LocationRequest
  ): ApiPromise<PendingClinicRegistrationResponse> {
    const url = `${this.baseUrl}/clinic-registration/${id}/location`;
    return axiosCommon.post(url, request);
  }

  static addCreditCard(
    id: string,
    request: CreditCardRequest
  ): ApiPromise<PendingClinicRegistrationResponse> {
    const url = `${this.baseUrl}/clinic-registration/${id}/credit-card`;
    return axiosCommon.post(url, request);
  }

  static finishRegistration(
    id: string
  ): ApiPromise<PendingClinicRegistrationResponse> {
    const url = `${this.baseUrl}/clinic-registration/${id}/finish`;
    return axiosCommon.post(url);
  }
}
