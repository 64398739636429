/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";
import AlertBox from "../../../components/AlertBox";
import { PendingClinicRegistrationResponse } from "../../../models/clinic/PendingClinicRegistrationResponse";
import ClinicRegistrationApi from "../../../api/ClinicRegistrationApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { AddressRequest } from "../../../models/contact/AddressRequest";

interface Props {
  registration?: PendingClinicRegistrationResponse;
  onNext(registration: PendingClinicRegistrationResponse): void;
  onError(error: string | null): void;
}

export interface AddressForm {
  city: string;
  houseDetails?: string;
  street: string;
  zip: string;
}

const AddressTab: React.FC<Props> = (props: Props) => {
  const { registration, onNext, onError } = props;
  const [isLoading, setLoading] = useState<boolean>(false);

  const defaultFormValues = (
    pending?: PendingClinicRegistrationResponse
  ): AddressForm => ({
    city: pending?.addresses[0]?.city || "",
    houseDetails: pending?.addresses[0]?.houseDetails || "",
    street: pending?.addresses[0]?.street || "",
    zip: pending?.addresses[0]?.zip || "",
  });

  const form = useForm<AddressForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(registration),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(defaultFormValues(registration));
  }, [registration]);

  const submit = async (data: AddressForm) => {
    if (!registration) return;
    setLoading(true);
    onError(null);

    try {
      const request: AddressRequest = {
        city: data.city,
        countryCode: registration.countryCode,
        houseDetails: data.houseDetails,
        street: data.street,
        zip: data.zip,
      };

      const response = await ClinicRegistrationApi.addAddress(
        registration.id,
        request
      );
      onNext(response.data);
    } catch (e) {
      onError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(submit)}>
        <div className="space-y-6">
          <Field
            autoComplete={AutoCompleteOptions.addressLine1}
            error={errors?.street}
            label={strings.addressLine1}
            name="street"
            register={register}
            required
            showRequired
          />
          <Field
            autoComplete={AutoCompleteOptions.addressLine2}
            error={errors?.houseDetails}
            label={strings.addressLine2}
            name="houseDetails"
            optional
            register={register}
          />
          <Field
            autoComplete={AutoCompleteOptions.addressLevel1}
            error={errors?.city}
            label={strings.city}
            name="city"
            register={register}
            required
            showRequired
          />
          <Field
            autoComplete={AutoCompleteOptions.postalCode}
            error={errors?.zip}
            label={strings.postalCode}
            name="zip"
            placeholder={strings.postalCode}
            register={register}
            required
            showRequired
          />
          <AlertBox
            hidden={
              errors?.street?.type !== "required" &&
              errors?.city?.type !== "required" &&
              errors?.zip?.type !== "required"
            }
            message={strings.pleaseFillRequiredFields}
          />
          <Button type="submit" loading={isLoading}>
            {strings.saveAndContinue}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddressTab;
