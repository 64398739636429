/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { PendingClinicRegistrationResponse } from "../../../models/clinic/PendingClinicRegistrationResponse";
import ClinicRegistrationApi from "../../../api/ClinicRegistrationApi";
import { getGeneralError } from "../../../util/helperFunctions";
import VerifyPhone from "../../../components/contact/user/phone/VerifyPhone";
import { PhoneConfirmationRequest } from "../../../models/contact/PhoneConfirmationRequest";

interface Props {
  registration?: PendingClinicRegistrationResponse;
  onNext(registration: PendingClinicRegistrationResponse): void;
  onError(error: string | null): void;
}

const PhoneConfirmationTab: React.FC<Props> = (props: Props) => {
  const { registration, onNext, onError } = props;
  const [isLoading, setLoading] = useState<boolean>(false);

  const submit = async (smsCode: string) => {
    if (!registration) return;
    setLoading(true);
    onError(null);

    try {
      const request: PhoneConfirmationRequest = { smsCode };
      const response = await ClinicRegistrationApi.confirmPhone(
        registration.id,
        request
      );
      onNext(response.data);
    } catch (e) {
      onError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  const resendCode = async () => {
    if (!registration) return;
    setLoading(true);
    onError(null);

    try {
      await ClinicRegistrationApi.sendPhoneConfirmation(registration.id);
    } catch (e) {
      onError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <VerifyPhone
        loading={isLoading}
        onSubmitCode={submit}
        phoneToConfirm={registration?.phones[0] || null}
        onResendCode={resendCode}
      />
    </div>
  );
};

export default PhoneConfirmationTab;
