/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { PageProps } from "../../models/PageProps";
import { Loader } from "../../components/Loader";
import { strings } from "../../common/Strings/Strings";
import { useClinic } from "../../contexts/ClinicContext";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import CountryApi from "../../api/CountryApi";
import logger from "../../util/logger";
import Income from "./Income";
import Usage from "./Usage";
import InventoryStockValue from "./InventoryStockValue";

const StatisticsPage = (props: PageProps): ReactElement => {
  const { setPageLoading } = props;
  const [countryDetails, setCountryDetails] = useState<CountryDetailsResponse>();
  const { clinic } = useClinic();

  useEffect(() => {
    const getCountryDetails = async () => {
      if (!clinic) {
        return;
      }

      try {
        const response = await CountryApi.getCountryDetailsOfClinic(clinic.id);
        setCountryDetails(response.data);
      } catch (err) {
        logger.error(err);
      } finally {
        setPageLoading(false);
      }
    };

    void getCountryDetails();
  }, [clinic, setPageLoading]);

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <div className="flex justify-between">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.statistics}
              </h1>
            </div>
            <Income countryDetails={countryDetails} />
            <InventoryStockValue />
            <Usage />
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(StatisticsPage);
