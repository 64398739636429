/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Route, Routes } from "react-router-dom";
import LocationIndicator from "../../../components/Tabs/LocationIndicator";
import TreatmentPage from "../../Treatments/TreatmentPage";
import NotFound from "../../NotFound";
import { SettingsTabs } from "../../../models/clinic/SettingsTabs";
import CollaboratorsTab from "./ClinicUsers/CollaboratorsTab";
import ReservationTypeTab from "./ReservationTypes/ReservationTypes";
import FeesTab from "./FeesDefault/FeesTab";
import NotificationsTab from "./Notifications/NotificationsTab";
import CalendarSettingsTab from "./Calendar/CalendarSettingsTab";
import InventorySettings from "./Inventory/InventorySettingsTab";
import AffiliatedPharmaciesTab from "./Pharmacies/AffiliatedPharmaciesTab";
import ClinicServiceFees from "../../ServiceFees/ClinicServiceFees";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import BasicInformationTab from "./BasicInfos/BasicInformationTab";
import { Notification } from "./ClinicSettings";
import { strings } from "../../../common/Strings/Strings";
import ContactInformation from "./Contacts/ContactInformation";
import BankAndBillingTab from "./BankAndBilling/BankAndBillingTab";
import AccountingCodesTab from "./AccountingCodes/AccountingCodesTab";
import { ClinicFeature } from "../../../models/clinic/ClinicFeature";
import { isFeatureAvailable } from "../../../util/helperFunctions";
import PaymentTab from "./Payment/PaymentTab";
import PrescriptionOnlyModeSection from "./BasicInfos/UsageModes/PrecriptionOnlyModeSection";

interface Props {
  clinic: ClinicResponse;
  getClinic: () => void;
  setClinic: (newClinic: ClinicResponse) => void;
  notification?: Notification;
  currentTab: SettingsTabs;
  setCurrentTab: (tab: SettingsTabs) => void;
}

const ClinicSettingsTabs: React.FC<Props> = ({
  clinic,
  getClinic,
  setClinic,
  notification,
  currentTab,
  setCurrentTab,
}: Props) => (
  <Routes>
    <Route
      path="/"
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.basic}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <BasicInformationTab clinic={clinic} refresh={getClinic} />
        </>
      }
    />
    <Route
      path={`${SettingsTabs.payment}`}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.payment}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <PaymentTab clinic={clinic}/>
        </>
      }
    />
    <Route
      path={`${SettingsTabs.contacts}`}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.contacts}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <ContactInformation clinic={clinic} />
        </>
      }
    />
    <Route
      path={`${SettingsTabs.bank}`}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.bank}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <BankAndBillingTab reqClinic={clinic} resClinic={getClinic} />
        </>
      }
    />
    <Route
      path={`${SettingsTabs.accountingCodes}`}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.accountingCodes}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <AccountingCodesTab
            clinic={clinic}
            isActiveTab={currentTab === SettingsTabs.accountingCodes}
            fortnoxEnabled={!!clinic.fortnoxEnabled}
          />
        </>
      }
    />
    <Route
      path={`${SettingsTabs.collaborators}`}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.collaborators}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <CollaboratorsTab
            isActiveTab={currentTab === SettingsTabs.collaborators}
            getClinic={getClinic}
            clinicId={clinic.id}
            clinic={clinic}
          />
        </>
      }
    />
    <Route
      path={`${SettingsTabs.reservationTypes}`}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.reservationTypes}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <ReservationTypeTab
            isActiveTab={currentTab === SettingsTabs.reservationTypes}
            clinic={clinic}
          />
        </>
      }
    />
    <Route
      path={`${SettingsTabs.notifications}`}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.notifications}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <NotificationsTab
            clinic={clinic}
            clinicId={clinic.id}
            notification={notification}
          />
        </>
      }
    />
    <Route
      path={`${SettingsTabs.treatments}`}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.treatments}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <TreatmentPage
            clinic={clinic}
            isActiveTab={currentTab === SettingsTabs.treatments}
          />
        </>
      }
    />
    <Route
      path={`${SettingsTabs.serviceFees}`}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.serviceFees}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <div className="w-full md:tw-card md:max-w-4xl space-y-6 md:p-8">
            <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
              {strings.serviceFees}
            </h2>
            {isFeatureAvailable(clinic, ClinicFeature.PRESCRIPTION) && (
              <FeesTab
                isActiveTab={currentTab === SettingsTabs.serviceFees}
                clinicResponse={clinic}
              />
            )}
            {isFeatureAvailable(clinic, ClinicFeature.SERVICE_FEES) && (
              <ClinicServiceFees
                clinic={clinic}
                clinicId={clinic.id}
                isActiveTab={currentTab === SettingsTabs.serviceFees}
              />
            )}
          </div>
        </>
      }
    />
    <Route
      path={`${SettingsTabs.other}`}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.other}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <div className="flex flex-col mt-6 items-center">
            <div className="w-full md:tw-card md:max-w-2xl space-y-6 md:p-8">
              <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {strings.otherSettings}
              </h2>
              {isFeatureAvailable(clinic, ClinicFeature.INVENTORY) && (
                <InventorySettings
                  clinic={clinic}
                  clinicId={clinic.id}
                  setClinic={(modifiedClinic) => {
                    setClinic(modifiedClinic);
                  }}
                />
              )}

              {isFeatureAvailable(clinic, ClinicFeature.CALENDAR) && (
                <CalendarSettingsTab
                  clinic={clinic}
                  clinicId={clinic.id}
                  setClinic={setClinic}
                />
              )}

              {isFeatureAvailable(clinic, ClinicFeature.PHARMACIES) && (
                <AffiliatedPharmaciesTab clinicResponse={clinic} />
              )}
              {clinic && isFeatureAvailable(clinic, ClinicFeature.FEATURE_SETTINGS) && (
                <PrescriptionOnlyModeSection clinic={clinic} refresh={getClinic} />
              )}
            </div>
          </div>
        </>
      }
    />
    <Route element={<NotFound />} />
  </Routes>
);

export default ClinicSettingsTabs;
