/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../common/Strings/Strings";
import { getGeneralError } from "../../util/helperFunctions";
import CloseButton from "../../components/CloseButton";
import { SiteManagerResponse } from "../../models/management/SiteManagerResponse";
import SiteManagerApi from "../../api/SiteManagerApi";
import Field from "../../components/ReactHookFormFields/General/Field";
import CheckBox from "../../components/ReactHookFormFields/General/CheckBox";
import { SiteManagerPermission } from "../../models/management/SiteManagerPermission";
import Tooltip from "../../components/Tooltip";
import Button from "../../components/Button";
import Modal from "../../components/Modal/Modal";
import BeforeDeleteModal from "../../components/Modals/BeforeDeleteModal";
import AlertBox from "../../components/AlertBox";
import { Card } from "../../components/Cards/Card";

interface Props {
  isActiveTab: boolean;
}

interface SiteManagerForm {
  [key: string]: any;
  email?: string;
  fullAccess: boolean;
}

export default function SiteManagersSection({
  isActiveTab,
}: Props): ReactElement {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [isEditorModalVisible, setEditorModalVisible] =
    useState<boolean>(false);
  const [isDeletionModalVisible, setDeletionModalVisible] =
    useState<boolean>(false);

  const [managers, setManagers] = useState<SiteManagerResponse[]>([]);
  const [selectedManager, setSelectedManager] = useState<SiteManagerResponse>();
  const [fullAccess, setFullAccess] = useState<boolean>();

  const siteManagerForm = useForm<SiteManagerForm>({
    mode: "onChange",
  });
  const { formState, register, handleSubmit } = siteManagerForm;

  const getSiteManagers = async () => {
    setLoading(true);
    try {
      const resp = await SiteManagerApi.getSiteManagers();
      setManagers(resp.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getSiteManagers();
    /* eslint-disable-next-line */
  }, [isActiveTab]);

  const openAddModal = () => {
    setEditorModalVisible(true);
    setSelectedManager(undefined);
    siteManagerForm.reset();
    setFullAccess(false);
    setError(null);
  };

  const closeEditorModal = () => {
    setEditorModalVisible(false);
    setSelectedManager(undefined);
    setError(null);
  };

  const openEditModal = (manager: SiteManagerResponse) => {
    setEditorModalVisible(true);
    setSelectedManager(manager);
    siteManagerForm.reset();
    siteManagerForm.setValue("email", manager.confirmedEmail?.value || "");
    siteManagerForm.setValue("fullAccess", manager.fullAccess);
    setFullAccess(manager.fullAccess);
    manager.permissions.forEach((permission) => {
      siteManagerForm.setValue(permission.name, permission.granted);
    });
    setError(null);
  };

  const openDeletionModal = (manager: SiteManagerResponse) => {
    setDeletionModalVisible(true);
    setSelectedManager(manager);
    setError(null);
  };

  const closeDeletionModal = () => {
    setDeletionModalVisible(false);
    setSelectedManager(undefined);
    setError(null);
  };

  const saveManager = async (data: SiteManagerForm) => {
    try {
      const request = {
        email: data.email || "",
        fullAccess: data.fullAccess,
        permissions: Object.keys(SiteManagerPermission).map((p) => ({
          name: p,
          granted: siteManagerForm.getValues(p) || false,
        })),
      };

      if (selectedManager) {
        await SiteManagerApi.updateSiteManager(selectedManager.id, request);
      } else {
        await SiteManagerApi.addSiteManager(request);
      }

      closeEditorModal();
      await getSiteManagers();
    } catch (e) {
      const err: any = e;
      setError(await getGeneralError(err));
      setLoading(false);
    }
  };

  const deleteManager = async () => {
    if (!selectedManager) {
      return;
    }

    setLoading(true);

    try {
      await SiteManagerApi.deleteSiteManager(selectedManager.id);
      await getSiteManagers();
    } catch (e) {
      setError(await getGeneralError(e));
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center md:mt-4">
        <Card
          loading={isLoading}
          type="simple"
          size="lg"
          title={strings.siteManagers}
          titleButtonContent={strings.addNew}
          titleButtonOnClick={openAddModal}
        >
          <AlertBox message={error} className="mb-2" />
          <div className="tw-table-container">
            <table className="tw-table">
              <thead className="tw-thead">
                <tr>
                  <th className="tw-th">{strings.users}</th>
                  <th className="tw-th">{strings.permissions}</th>
                  <th className="tw-th" />
                </tr>
              </thead>
              <tbody className="tw-tbody">
                {managers.map((manager) => (
                  <tr key={manager.id}>
                    <td className="px-4 py-3 ">
                      <div className="text-sm">{manager.fullName}</div>
                      <div className="text-xs text-secondary">
                        {manager.confirmedEmail?.value}
                      </div>
                    </td>
                    <td className="px-4 py-3">
                      <div className="d-flex flex-wrap">
                        {manager.permissions.map((permission) => (
                          <div key={permission.name}>
                            <Tooltip
                              content={getStringFromEnumsOrReturn(
                                permission.name
                              )}
                              placement="top"
                            >
                              <div
                                className={`badge ${
                                  permission.granted || manager.fullAccess
                                    ? "badge-success"
                                    : "badge-simple"
                                } mr-1`}
                              >
                                {getStringFromEnumsOrReturn(permission.name)[0]}
                              </div>
                            </Tooltip>
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="px-4 py-3 text-right whitespace-nowrap">
                      <Button
                        className="mr-2"
                        variant="secondary"
                        onClick={() => openEditModal(manager)}
                      >
                        <i className="fas fa-pencil-alt" />
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => openDeletionModal(manager)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </div>

      <Modal show={isEditorModalVisible} handleClose={closeEditorModal}>
        <Modal.Header closeButton title={strings.siteManager}></Modal.Header>
        <Modal.Body>
          <FormProvider {...siteManagerForm}>
            <form onSubmit={handleSubmit(saveManager)}>
              <Field
                label={strings.email}
                name="email"
                register={register}
                required={!selectedManager}
                readOnly={selectedManager !== undefined}
                error={formState.errors.email}
              />
              <CheckBox
                className="mt-3"
                name="fullAccess"
                register={register}
                label={strings.fullAccess}
                onChange={(value) => setFullAccess(value)}
              />
              <div className="ml-4 mt-2 space-y-1">
                {Object.keys(SiteManagerPermission).map((permission) => (
                  <div key= {permission}>
                    <CheckBox
                      name={permission}
                      register={register}
                      label={getStringFromEnumsOrReturn(permission)}
                      readOnly={fullAccess}
                    />
                  </div>
                ))}
              </div>
              <AlertBox message={error} className="mt-3" />
            </form>
          </FormProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-main-button"
            variant="primary"
            type="submit"
            disabled={!formState.isValid}
            onClick={handleSubmit(saveManager)}
          >
            {strings.save}
          </Button>
          <CloseButton onClick={closeEditorModal} />
        </Modal.Footer>
      </Modal>

      <BeforeDeleteModal
        open={isDeletionModalVisible}
        onHide={closeDeletionModal}
        returnYes={() => {
          closeDeletionModal();
          void deleteManager();
        }}
      />
    </>
  );
}
