/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import CountryApi from "../../../api/CountryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox from "../../../components/AlertBox";
import { CountryPriceSettingsResponse } from "../../../models/management/CountryPriceSettingsResponse";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { CountrySettingsResponse } from "../../../models/management/CountrySettingsResponse";
import FeesModal from "./FeesModal";
import Button from "../../../components/Button";
import { PencilSquare } from "../../../common/Icons/PencilSquare";
import { Trash } from "../../../common/Icons/Trash";
import { PlusIcon } from "../../../common/Icons/PlusIcon";

interface Props {
  isActiveTab: boolean;
  countryDetails: CountryDetailsResponse;
  country: CountrySettingsResponse;
}

const PricesSection = ({ isActiveTab, country, countryDetails }: Props): ReactElement => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<CountryPriceSettingsResponse>();
  const [priceSettings, setPriceSettings] = useState<CountryPriceSettingsResponse[]>();

  const [error, setError] = useState<string | null>(null);

  const getPriceSettings = async () => {
    try {
      setIsLoaded(false);
      const resp = await CountryApi.getCountryPriceSettings(country.countryCode);
      setPriceSettings(resp.data);
      setIsLoaded(true);
    } catch (err) {
      setError(await getGeneralError(err));
      setIsLoaded(true);
    }
  };

  const deletePriceSettings = async (id: string) => {
    try {
      setIsLoaded(false);
      await CountryApi.deleteCountryPriceSettings(country.id, id);
      await getPriceSettings();
      setIsLoaded(true);
    } catch (err) {
      setError(await getGeneralError(err));
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getPriceSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, countryDetails, isActiveTab]);

  const openModal = (item?: CountryPriceSettingsResponse) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedItem(undefined);
    void getPriceSettings();
  };

  return (
    <>
      <LoaderInline className="my-3" hidden={isLoaded} />
      <div hidden={!isLoaded || error == null}>
        <AlertBox message={error} className="my-3" />
      </div>
      <div hidden={!isLoaded || error != null}>
        {priceSettings && (
          <div className="space-y-6 tw-card p-4">
            <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
              {strings.priceLists}
            </h2>
            <Button variant="link" onClick={() => openModal()}>
              <PlusIcon /> {strings.addNew}
            </Button>
            <div className="divide-y dark:divide-gray-700">
              {priceSettings.map((prices) => (
                <div className="flex items center py-2" key={prices.id}>
                  <div>{moment(prices.validityStart).format(dateAndTime.momentDateFormat)}</div>
                  <div className="ml-auto">
                    <Button className="mr-2" variant="icon" onClick={() => openModal(prices)}>
                      <PencilSquare />
                    </Button>
                    <Button variant="icon" onClick={() => deletePriceSettings(prices.id)}>
                      <Trash />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <FeesModal
        country={country}
        countryDetails={countryDetails}
        isOpen={isModalOpen}
        close={closeModal}
        settings={selectedItem}
      />
    </>
  );
};

export default PricesSection;
