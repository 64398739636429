/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import Button, { ButtonVariant } from "../Button";
import { strings } from "../../common/Strings/Strings";
import { CardWrapper } from "./CardWrapper";
import LoaderInline from "../LoaderInline";
import { combineClassNames } from "../../util/HtmlUtils";

// Full: A full screen single card
// Simple: An inline simple card without extra spacings
type CardType = "full" | "simple";
type CardSize = "sm" | "md" | "lg" | "xl" | "max" | "";

interface Props {
  actionText?: string;
  children: React.ReactNode;
  footerText?: string;
  loading?: boolean;
  marginClass?: string;
  onAction?: () => void;
  paddingClass?: string;
  size?: CardSize;
  spaceYClass?: string;
  style?: React.CSSProperties;
  title?: string;
  titleButtonContent?: ReactElement;
  titleButtonOnClick?(): void;
  titleButtonVariant?: ButtonVariant;
  type?: CardType;
}

export const Card: React.FC<Props> = ({
  children,
  actionText = strings.skipForNow,
  footerText,
  loading = false,
  marginClass = "mt-20 md:mt-0",
  onAction,
  paddingClass = "md:p-8",
  size = "",
  spaceYClass = "space-y-6",
  style,
  title,
  titleButtonContent = "",
  titleButtonOnClick,
  titleButtonVariant = "link",
  type = "full",
}) => {
  let sizeClass: string;

  switch (size) {
    case "sm":
      sizeClass = "md:w-[300px]";
      break;
    case "md":
      sizeClass = "md:w-[500px]";
      break;
    case "lg":
      sizeClass = "md:w-[800px]";
      break;
    case "xl":
      sizeClass = "md:w-[1140px]";
      break;
    case "max":
      sizeClass = "md:w-full";
      break;
    default:
      sizeClass = "";
  }

  return (
    <CardWrapper wrap={type === "full"}>
      <section
        className={combineClassNames(
          "w-full md:tw-card",
          spaceYClass,
          paddingClass,
          marginClass,
          sizeClass === "" ? "md:max-w-md" : sizeClass,
          type === "simple" ? marginClass : ""
        )}
        style={style}
      >
        {title && (
          <h1 className="flex text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
            {title}
            {titleButtonOnClick && (
              <div className="ml-auto">
                <Button className="text-base" onClick={titleButtonOnClick} variant={titleButtonVariant}>
                  {titleButtonContent}
                </Button>
              </div>
            )}
          </h1>
        )}
        {loading ? <LoaderInline className="my-10" /> : children}
        {onAction && (
          <div className="grid grid-cols-2 gap-4">
            {footerText && (
              <div className="flex justify-start">
                <span className="text-left font-medium text-sm text-gray-500 dark:text-gray-400">{footerText}</span>
              </div>
            )}
            <div className={`flex ${footerText ? "justify-end" : "justify-start"}`}>
              <Button
                onClick={onAction}
                variant="link"
                className={`text-sm p-0 ${footerText ? "text-left" : "text-right"}`}
              >
                {actionText}
              </Button>
            </div>
          </div>
        )}
      </section>
    </CardWrapper>
  );
};
