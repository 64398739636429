/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import UrlTab from "../../../components/Tabs/UrlTab";
import { strings } from "../../../common/Strings/Strings";
import { ClinicFeature } from "../../../models/clinic/ClinicFeature";
import { SettingsTabs } from "../../../models/clinic/SettingsTabs";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import { isFeatureAvailable } from "../../../util/helperFunctions";
import { useUser } from "../../../contexts/UserContext";

interface Props {
  clinic: ClinicResponse;
  currentTab: SettingsTabs;
}

const ClinicSettingsMenu: React.FC<Props> = ({ clinic, currentTab }: Props) => { 
  const { user } = useUser();

  return (
    <ul className="flex flex-wrap -mb-px">
      <UrlTab
        useBaseUrlOnly
        currentTabKey={currentTab}
        tabKey={SettingsTabs.basic}
        title={strings.basicInfo}
      />
      <UrlTab
        currentTabKey={currentTab}
        tabKey={SettingsTabs.payment}
        title={strings.payments}
        visible={clinic.owner?.userId === user?.userId}
      />
      <UrlTab
        currentTabKey={currentTab}
        tabKey={SettingsTabs.contacts}
        title={strings.contactInfo}
      />
      <UrlTab
        currentTabKey={currentTab}
        tabKey={SettingsTabs.bank}
        title={strings.bankAndBilling}
      />
      <UrlTab
        currentTabKey={currentTab}
        tabKey={SettingsTabs.accountingCodes}
        title={strings.accountingCodes}
        visible={isFeatureAvailable(clinic, ClinicFeature.ACCOUNTING_CODES)}
      />
      <UrlTab
        currentTabKey={currentTab}
        tabKey={SettingsTabs.collaborators}
        title={strings.collaborators}
      />
      <UrlTab
        currentTabKey={currentTab}
        tabKey={SettingsTabs.reservationTypes}
        title={strings.reservationTypes}
        visible={isFeatureAvailable(clinic, ClinicFeature.RESERVATION_TYPES)}
      />
      <UrlTab
        currentTabKey={currentTab}
        tabKey={SettingsTabs.notifications}
        title={strings.notifications}
        visible={isFeatureAvailable(clinic, ClinicFeature.RESERVATION)}
      />
      <UrlTab
        currentTabKey={currentTab}
        tabKey={SettingsTabs.treatments}
        title={strings.treatments}
        visible={isFeatureAvailable(clinic, ClinicFeature.TREATMENTS)}
      />
      <UrlTab
        currentTabKey={currentTab}
        tabKey={SettingsTabs.serviceFees}
        title={strings.serviceFees}
        visible={isFeatureAvailable(clinic, ClinicFeature.SERVICE_FEES)}
      />
      <UrlTab
        currentTabKey={currentTab}
        tabKey={SettingsTabs.other}
        title={strings.other}
      />
    </ul>
  );
};

export default ClinicSettingsMenu;
