/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import { strings } from "../../common/Strings/Strings";
import { calculateProfilePictureSize } from "./Utils";
import { userProfilePlaceholder } from "../../assets/AssetConfig";

interface Props {
  cardHeight: number;
  hiddenVideosCount: number;
  invisibleVideoIds: Array<string>;
}

const renderProfilePicture = (userId: string) => {
  if (userId === "") {
    return (
      <img
        className="bg-center bg-cover bg-no-repeat bg-white border-gray-300 border-solid h-full object-cover rounded-full w-full"
        style={{ borderWidth: "4px" }}
        alt="User profile placeholder"
        src={userProfilePlaceholder}
      />
    );
  }

  return <UserProfilePicture borderColor="border-gray-300" userId={userId} />;
};

const renderProfilePictures = (invisibleVideoIds: Array<string>, cardHeight: number): ReactElement => {
  const pictureCount = invisibleVideoIds.length;
  const profilePictureSize = calculateProfilePictureSize(cardHeight, 1 / 3);
  const profilePictureStyle = {
    width: profilePictureSize.width,
    height: profilePictureSize.height,
  };

  if (pictureCount === 2) {
    return (
      <>
        <div className="absolute translate-x-3" style={profilePictureStyle}>
          {renderProfilePicture(invisibleVideoIds[0])}
        </div>
        <div className="absolute -translate-x-3" style={profilePictureStyle}>
          {renderProfilePicture(invisibleVideoIds[1])}
        </div>
      </>
    );
  }

  if (pictureCount >= 3) {
    return (
      <>
        <div className="absolute translate-x-5" style={profilePictureStyle}>
          {renderProfilePicture(invisibleVideoIds[0])}
        </div>
        <div className="absolute" style={profilePictureStyle}>
          {renderProfilePicture(invisibleVideoIds[1])}
        </div>
        <div className="absolute -translate-x-5" style={profilePictureStyle}>
          {renderProfilePicture(invisibleVideoIds[2])}
        </div>
      </>
    );
  }

  return <></>;
};

/*
 * A collector card component where there are many users are some became invisible.
 * This card shows the hidden video count are some profile pictures of the hidden users.
 */
export const HiddenVideosCollectorCard: React.FC<Props> = ({
  cardHeight,
  hiddenVideosCount,
  invisibleVideoIds,
}: Props): ReactElement => {
  const handleClick = () => {
    console.log("Clicked!");
  };

  return (
    <div
      className="relative w-full h-full flex justify-center items-center rounded-2xl"
      onClick={handleClick}
      role="button"
      tabIndex={-1}
    >
      {/* Hidden videos card */}
      <div className="flex justify-center items-center w-full h-full border-4 rounded-xl border-transparent bg-gray-600">
        {renderProfilePictures(invisibleVideoIds, cardHeight)}
      </div>
      {/* Hidden videos count and text */}
      <p className="text-xl text-bold text-white absolute top-5 bg-opacity-40 rounded-full p-2">
        {hiddenVideosCount} {strings.hiddenVideosText}
      </p>
    </div>
  );
};
