/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import Params from "../common/Params";
import moment from "moment/moment";
import { StockValuesResponse } from "../models/inventory/statistics/StockValuesResponse";

export default class InventoryStatisticsApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getStockValues(clinicId: string, date?: Date): ApiPromise<StockValuesResponse> {
    let url = `${this.baseUrl}/clinics/${clinicId}/inventories/statistics/stock-values?`;
    if (date) {
      url += `date=${moment(date).format("YYYY-MM-DD")}`;
    }
    return axiosCommon.get(url);
  }

  static getStockValuesPdf(clinicId: string, date?: Date): ApiPromise<BlobPart> {
    let url = `${this.baseUrl}/clinics/${clinicId}/inventories/statistics/stock-values/pdf?`;
    if (date) {
      url += `date=${moment(date).format("YYYY-MM-DD")}`;
    }
    return axiosCommon.get(url, {
      headers: { Accept: "*/*" },
      responseType: "arraybuffer",
    });
  }
}
