/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import CloseButton from "../../../components/CloseButton";
import AlertBox from "../../../components/AlertBox";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";
import { DataExportRequest } from "../../../models/management/DataExportRequest";
import ClinicManagementApi from "../../../api/ClinicManagementApi";
import { ClinicDetailsResponse } from "../../../models/management/ClinicDetailsResponse";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  clinic: ClinicDetailsResponse;
}

const defaultFormValues = (clinic: ClinicDetailsResponse) => {
  return {
    email: clinic.emails[0].value,
  };
};

const ClinicDataExportModal: React.FC<Props> = (props: Props) => {
  const { isVisible, onClose, clinic } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const sendDataExport = async (data: DataExportRequest) => {
    setLoading(true);
    try {
      await ClinicManagementApi.sendClinicExportMail(clinic.id, data);
      onClose();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<DataExportRequest>({
    mode: "onChange",
    defaultValues: defaultFormValues(clinic),
  });

  useEffect(() => {
    if (isVisible) {
      reset(defaultFormValues(clinic));
      setError(null);
    }
  }, [clinic, reset, isVisible]);

  return (
    <Modal size="lg" show={isVisible} onHide={onClose} backdrop="static">
      <form onSubmit={handleSubmit(sendDataExport)}>
        <Modal.Header closeButton>
          <Modal.Title>{strings.exportClinicData}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Field
              name="email"
              type="email"
              label={strings.email}
              register={register}
              autoComplete={AutoCompleteOptions.email}
              required
              error={errors.email}
            />
          </div>
          <AlertBox message={error} className="mb-2" />
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={onClose} />
          <Button disabled={!isValid || isLoading} loading={isLoading} type="submit" className="modal-main-button">
            {strings.emailSend}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ClinicDataExportModal;
