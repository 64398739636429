/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { strings } from "../../common/Strings/Strings";
import { useClinic } from "../../contexts/ClinicContext";
import AddressTab from "./CreateClinicSteps/AddressTab";
import ContactsTab from "./CreateClinicSteps/ContactsTab";
import LocationTab from "./CreateClinicSteps/LocationTab";
import { CountryResponse } from "../../models/management/CountryResponse";
import CountryApi from "../../api/CountryApi";
import CreditCardTab from "./CreateClinicSteps/CreditCardTab";
import { getGeneralError } from "../../util/helperFunctions";
import AlertBox, { AlertType } from "../../components/AlertBox";
import SuccessTab from "./CreateClinicSteps/SuccessTab";
import { PageProps } from "../../models/PageProps";
import { Loader } from "../../components/Loader";
import { PendingClinicRegistrationResponse } from "../../models/clinic/PendingClinicRegistrationResponse";
import ClinicRegistrationApi from "../../api/ClinicRegistrationApi";
import PhoneConfirmationTab from "./CreateClinicSteps/PhoneConfirmationTab";
import BasicInformationTab from "./CreateClinicSteps/BasicInformationTab";

enum Tabs {
  BASIC_INFO = strings.basicInfo,
  CONTACTS = strings.contacts,
  PHONE_CONFIRMATION = strings.verifyYourPhone,
  ADDRESS = strings.address,
  LOCATION = strings.location,
  BANK_CARD = strings.creditCardDetails,
  SUCCESS = strings.success,
}

const tabsInOrder = [
  Tabs.BASIC_INFO,
  Tabs.CONTACTS,
  Tabs.PHONE_CONFIRMATION,
  Tabs.ADDRESS,
  Tabs.LOCATION,
  Tabs.BANK_CARD,
  Tabs.SUCCESS,
];

const CreateClinic: React.FC<PageProps> = (props) => {
  const { setPageLoading } = props;
  const navigate = useNavigate();
  const { fetchClinics } = useClinic();

  const [countriesLoading, setCountriesLoading] = useState<boolean>(false);
  const [pendingRegistrationLoading, setPendingRegistrationLoading] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.BASIC_INFO);

  const [countryOptions, setCountryOptions] = useState<CountryResponse[]>([]);
  const [pendingRegistration, setPendingRegistration] =
    useState<PendingClinicRegistrationResponse>();
  const [
    isPendingRegistrationAlertVisible,
    setPendingRegistrationAlertVisible,
  ] = useState<boolean>(false);

  const nextStep = () => {
    setPendingRegistrationAlertVisible(false);
    if (tabIndex === tabsInOrder.length - 1) {
      return;
    }
    let newIndex = tabIndex + 1;
    if (
      tabsInOrder[newIndex] == Tabs.PHONE_CONFIRMATION &&
      pendingRegistration?.phones[0].confirmed
    ) {
      newIndex += 1;
    }
    setActiveTab(tabsInOrder[newIndex]);
    setTabIndex(newIndex);
  };

  const previousStep = () => {
    setPendingRegistrationAlertVisible(false);
    if (tabIndex === 0) {
      navigate(-1);
    }
    let newIndex = tabIndex - 1;
    if (
      tabsInOrder[newIndex] == Tabs.PHONE_CONFIRMATION &&
      pendingRegistration?.phones[0].confirmed
    ) {
      newIndex -= 1;
    }
    setActiveTab(tabsInOrder[newIndex]);
    setTabIndex(newIndex);
  };

  const getPendingRegistration = async () => {
    setPendingRegistrationLoading(true);
    try {
      const response = await ClinicRegistrationApi.getPendingRegistration();
      setPendingRegistration(response.data);
      setPendingRegistrationAlertVisible(!!response.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPendingRegistrationLoading(false);
    }
  };

  const createClinic = async () => {
    if (!pendingRegistration) {
      return;
    }
    setPageLoading(true);

    try {
      await ClinicRegistrationApi.finishRegistration(pendingRegistration.id);
      fetchClinics();
      nextStep();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      setCountriesLoading(true);
      try {
        const response = await CountryApi.getCountries();
        setCountryOptions(response.data);
      } catch (err) {
        setError(strings.simpleError);
      } finally {
        setCountriesLoading(false);
      }
    };

    void getCountries();
    void getPendingRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageLoading(countriesLoading || pendingRegistrationLoading);
  }, [countriesLoading, pendingRegistrationLoading]);

  return (
    <main className="main-default">
      <section>
        <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <div className="w-full md:max-w-md md:tw-card space-y-6 md:p-8">
            <div className="flex justify-between space-x-4">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.createNewClinic}
              </h1>
              <span
                className="text-gray-500 dark:text-gray-400"
                hidden={activeTab === Tabs.SUCCESS}
              >
                {tabIndex + 1}/{tabsInOrder.length - 1}
              </span>
            </div>
            <div hidden={activeTab === Tabs.SUCCESS}>
              <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {activeTab}
              </h2>
            </div>
            <AlertBox message={error} />
            {isPendingRegistrationAlertVisible && !error && (
              <AlertBox
                type={AlertType.INFO}
                closeAble={false}
                message={
                  <>
                    <div>
                      {strings.formatString(
                        strings.pendingRegistrationAlert,
                        pendingRegistration?.name
                      )}
                    </div>
                    <Button
                      variant="link"
                      className="px-0 text-sm"
                      onClick={() => {
                        setPendingRegistration(undefined);
                        setPendingRegistrationAlertVisible(false);
                      }}
                    >
                      {strings.startNewRegistration}
                    </Button>
                  </>
                }
              />
            )}
            <div>
              {activeTab === Tabs.BASIC_INFO && (
                <BasicInformationTab
                  countries={countryOptions}
                  onError={(e) => setError(e)}
                  registration={pendingRegistration}
                  onNext={(registration) => {
                    setPendingRegistration(registration);
                    nextStep();
                  }}
                />
              )}
              {activeTab === Tabs.CONTACTS && (
                <ContactsTab
                  onError={(e) => setError(e)}
                  registration={pendingRegistration}
                  onNext={(registration) => {
                    setPendingRegistration(registration);
                    nextStep();
                  }}
                />
              )}
              {activeTab === Tabs.PHONE_CONFIRMATION && (
                <PhoneConfirmationTab
                  onError={(e) => setError(e)}
                  registration={pendingRegistration}
                  onNext={(registration) => {
                    setPendingRegistration(registration);
                    nextStep();
                  }}
                />
              )}
              {activeTab === Tabs.ADDRESS && (
                <AddressTab
                  onError={(e) => setError(e)}
                  registration={pendingRegistration}
                  onNext={(registration) => {
                    setPendingRegistration(registration);
                    nextStep();
                  }}
                />
              )}
              {activeTab === Tabs.LOCATION && (
                <LocationTab
                  countries={countryOptions}
                  onPrevious={previousStep}
                  onError={(e) => setError(e)}
                  registration={pendingRegistration}
                  onNext={(registration) => {
                    setPendingRegistration(registration);
                    nextStep();
                  }}
                />
              )}
              {activeTab === Tabs.BANK_CARD && (
                <CreditCardTab
                  loading={loading}
                  setLoading={setLoading}
                  onError={(e) => setError(e)}
                  registration={pendingRegistration}
                  onNext={(registration) => {
                    setPendingRegistration(registration);
                    void createClinic();
                  }}
                />
              )}
              {activeTab === Tabs.SUCCESS && (
                <SuccessTab
                  onFinish={() => {
                    navigate(`/`, { replace: true });
                  }}
                />
              )}
            </div>
            <div className="flex justify-between">
              {activeTab === Tabs.BASIC_INFO ? (
                <Link to="/globalvet/clinics">{strings.back}</Link>
              ) : (
                <Button
                  className="text-left text-sm p-0"
                  hidden={activeTab === Tabs.SUCCESS}
                  onClick={previousStep}
                  variant="link"
                >
                  {tabIndex === 0 ? strings.cancel : strings.back}
                </Button>
              )}

              <div id="dev-only" hidden>
                <Button
                  className="text-right text-sm p-0"
                  hidden={activeTab === Tabs.SUCCESS}
                  onClick={nextStep}
                  variant="link"
                >
                  {strings.next}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(CreateClinic);
