/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import PinCodeSettings from "../UserManagement/ProfileSettings/ProfileSettingsTabs/SecurityTab/PinCodeSettings/PinCodeSettings";
import { strings } from "../../common/Strings/Strings";
import { DocumentsUploadPage } from "../UserManagement/VetProfessionDocument/DocumentsUploadPage";
import VetRegistrationNumberModal from "../UserManagement/ProfileSettings/ProfileSettingsTabs/VetTab/VetRegistrationNumber/VetRegistrationNumberModal";
import Modal from "../../components/Modal/Modal";

type ProfileSetupStage = "PINCODE" | "VETREGNUMBER" | "VETDOCUPLOAD";

const SettingsReminders: React.FC = () => {
  const [remainingSteps, setRemainingSteps] = useState<ProfileSetupStage[]>([]);
  const [step, setStep] = useState<ProfileSetupStage>("VETREGNUMBER");
  const { user } = useUser();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const states: ProfileSetupStage[] = [];

    if (!user.hasConfirmedPinCode && user.addresses?.[0]?.countryCode === "SE") {
      states.push("PINCODE");
    }

    if (user.vetRegistrationNumbers.length === 0 && user.addresses?.[0]?.countryCode === "SE") {
      states.push("VETREGNUMBER");
    }

    if (user?.vetProfession?.state !== "APPROVED" && user.addresses?.[0]?.countryCode === "SE") {
      states.push("VETDOCUPLOAD");
    }

    setRemainingSteps(states);
    if (states.length > 0) {
      openModal();
    }
  }, [user]);

  useEffect(() => {
    if (remainingSteps.length === 0) {
      closeModal();
    } else {
      openModal();
      setStep(remainingSteps[0]);
    }
  }, [remainingSteps]);

  const skip = (stage: ProfileSetupStage) => {
    setRemainingSteps([...remainingSteps.filter((s) => s !== stage)]);
  };

  switch (step) {
    case "PINCODE":
      return (
        <Modal
          handleClose={() => {
            skip("PINCODE");
            closeModal();
          }}
          id="handleCloseModal"
          show={isModalOpen}
        >
          <Modal.Header title={strings.setPinNumber} />
          <Modal.Body>
            <PinCodeSettings asCard={false} canCancel />
          </Modal.Body>
        </Modal>
      );
    case "VETDOCUPLOAD":
      return (
        <Modal
          handleClose={() => {
            skip("VETDOCUPLOAD");
            closeModal();
          }}
          id="vetDocUploadModal"
          show={isModalOpen}
          size="modal-sm"
        >
          <Modal.Header title={strings.medicalCertificate} />
          <Modal.Body>
            <DocumentsUploadPage
              onSuccess={() => {
                skip("VETDOCUPLOAD");
              }}
            />
          </Modal.Body>
        </Modal>
      );
    case "VETREGNUMBER":
      return (
        <VetRegistrationNumberModal
          close={() => {
            skip("VETREGNUMBER");
            closeModal();
          }}
          isOpen={isModalOpen}
          onSuccess={() => {
            skip("VETREGNUMBER");
          }}
        />
      );
    default:
      return <></>;
  }
};

export default SettingsReminders;
