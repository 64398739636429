/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { FieldError, RegisterOptions } from "react-hook-form";
import { strings } from "../../../../../common/Strings/Strings";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { RegisterReturnType } from "../../../../../models/RegisterReturnType";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";

interface Props {
  register(ref: string, registerOptions?: RegisterOptions): RegisterReturnType;
  error?: FieldError;
}

/*
 * Chromium based browsers decided to ignore the autocomplete attribute on inputs.
 * (They are referring to incorrect usage by developers,
 * like mostly using autocomplete="off" as default where it would be useful.)
 * More information:
 * https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
 * https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
 * https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164
 * The officially suggested workarounds does not work anymore, so we dynamically change the input type on this field.
 * The purpose of this is to disable autocomplete initially and still display the field as password.
 * Note: Completely deleting the input text somehow enables autocomplete again, but it's probably not
 * a frequent use case, and on top of that there's probably no simple workarounds for that.
 */
const ManualOwnerPasswordInput: React.FC<Props> = ({
  register,
  error,
}: Props) => {
  const [type, setType] = useState<"text" | "password">("text");
  return (
    <Field
      name="password"
      label={strings.organizationOwnersPassword}
      type={type}
      onChange={(event) =>
        setType(event.target.value.length === 0 ? "text" : "password")
      }
      register={register}
      autoComplete={AutoCompleteOptions.off}
      fieldOptions={{ required: true }}
      error={error}
    />
  );
};

export default ManualOwnerPasswordInput;
