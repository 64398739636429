/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../../components/AlertBox";
import { getGeneralError } from "../../../util/helperFunctions";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";
import Field from "../../../components/ReactHookFormFields/General/Field";
import moment from "moment/moment";
import LoaderInline from "../../../components/LoaderInline";
import { ClinicChargeRequest } from "../../../models/management/ClinicChargeRequest";
import { ClinicChargeResponse } from "../../../models/charge/ClinicChargeResponse";
import ChargeManagementApi from "../../../api/ChargeManagementApi";
import CloseButton from "../../../components/CloseButton";
import CostSummarySection from "../../Clinic/Settings/Payment/CostSummarySection";

interface Props {
  clinicId: string;
  onHide: () => void;
  show: boolean;
}

interface ClinicChargeForm {
  startDate: string;
  endDate: string;
}

const StartClinicChargeModal: React.FC<Props> = (props: Props) => {
  const { clinicId, onHide, show } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isPreviewLoading, setPreviewLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [charge, setCharge] = useState<ClinicChargeResponse>();

  const { watch, reset, register, handleSubmit } = useForm<ClinicChargeForm>({
    mode: "onChange",
    defaultValues: {
      startDate: moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD"),
    },
  });

  useLayoutEffect(() => {
    reset();
    setError(null);
  }, [reset, clinicId, show]);

  const [startDate, endDate] = watch(["startDate", "endDate"]);

  const getPreview = async () => {
    try {
      setPreviewLoading(true);
      const request: ClinicChargeRequest = {
        clinicId,
        startDate,
        endDate,
      };
      const response = await ChargeManagementApi.createClinicChargePreview(
        request
      );
      setCharge(response.data);
      setError(null);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setPreviewLoading(false);
    }
  };

  const chargeClinic = async (formData: ClinicChargeForm) => {
    setLoading(true);

    const request: ClinicChargeRequest = {
      clinicId,
      startDate: formData.startDate,
      endDate: formData.endDate,
    };

    try {
      await ChargeManagementApi.createClinicCharge(request);

      onHide();
      setError(null);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getPreview();
  }, [clinicId, startDate, endDate]);

  return (
    <>
      <Modal size="modal-lg" show={show} handleClose={onHide}>
        <Modal.Header closeButton title={strings.chargeClinic}></Modal.Header>
        <Modal.Body>
          <form>
            <div className="row mb-5">
              <div className="col">
                <Field
                  register={register}
                  type="date"
                  label={strings.startDate}
                  name="startDate"
                />
              </div>
              <div className="col">
                <Field
                  register={register}
                  type="date"
                  label={strings.endDate}
                  name="endDate"
                />
              </div>
            </div>
            <div hidden={!isPreviewLoading}>
              <LoaderInline />
            </div>
            <div hidden={isPreviewLoading}>
              <CostSummarySection hideForecast={true} costs={charge} />
            </div>
            <AlertBox message={error} className="my-3" />
          </form>
        </Modal.Body>

        <Modal.Footer>
          <CloseButton onClick={onHide} />
          <Button
            className="modal-main-button"
            loading={isLoading}
            disabled={isLoading || isPreviewLoading}
            onClick={handleSubmit(chargeClinic)}
          >
            {strings.chargeClinic}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StartClinicChargeModal;
