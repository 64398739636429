/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { strings } from "../../../common/Strings/Strings";
import { InvoiceItemResponse } from "../../../models/invoice/InvoiceItemResponse";
import NumberFormatter from "../../../util/NumberFormatter";
import CostCalculator from "../../../util/CostCalculator";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import CountryApi from "../../../api/CountryApi";
import logger from "../../../util/logger";
import { useClinic } from "../../../contexts/ClinicContext";
import { Link } from "react-router-dom";

interface Props {
  invoice: InvoiceResponse;
}

const InvoiceItems = ({ invoice }: Props): ReactElement => {
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const { clinic } = useClinic();

  useEffect(() => {
    const getCountryDetails = async () => {
      if (!clinic) {
        return;
      }

      try {
        const response = await CountryApi.getCountryDetailsOfClinic(clinic.id);
        setCountryDetails(response.data);
      } catch (err) {
        logger.error(err);
      }
    };

    void getCountryDetails();
  }, [clinic]);

  const getInvoiceItemLink = (item: InvoiceItemResponse) => {
    switch (item.type) {
      case "INVENTORY_ITEM":
        return (
          <Link
            className="tw-link-dark"
            to={`/inventory/item/${item.referenceId}/details`}
          >
            {item.name}
          </Link>
        );
      case "TREATMENT":
        return (
          <Link className="tw-link-dark" to={`/treatments/${item.referenceId}`}>
            {item.name}
          </Link>
        );
      default:
        return item.name;
    }
  };

  return (
    <div className="tw-table-container">
      <table className="tw-table">
        <thead className="tw-thead">
          <tr>
            <th className="tw-th">{strings.name}</th>
            <th className="tw-th">{strings.accountingCode}</th>
            <th className="tw-th">{strings.vatAsTax}</th>
            <th className="tw-th">{strings.quantity}</th>
            <th className="tw-th">{strings.unit}</th>
            <th className="tw-th">{strings.unitPrice}</th>
            <th className="tw-th">{`${strings.total} (${strings.net})`}</th>
            <th className="tw-th">{`${strings.total} (${strings.gross})`}</th>
          </tr>
        </thead>
        <tbody className="tw-tbody">
          {invoice?.items?.map((item: InvoiceItemResponse, index) => (
            <tr key={`${item.name}-${index}`}>
              <td className="px-4 py-2 text-sm">{clinic?.id === invoice.clinicId ? getInvoiceItemLink(item) : item.name}</td>
              <td className="px-4 py-2 text-sm whitespace-nowrap">
                {item.accountingCode}
              </td>
              <td className="px-4 py-2text-sm whitespace-nowrap">{`${item.vat} %`}</td>
              <td className="px-4 py-2 text-sm whitespace-nowrap">
                {item.quantity}
              </td>
              <td className="px-4 py-2 text-sm whitespace-nowrap">
                {item.unit}
              </td>
              <td className="px-4 py-2 text-sm whitespace-nowrap">
                {NumberFormatter.formatPrice(item.price, invoice.currency)}
              </td>
              <td className="px-4 py-2 text-sm whitespace-nowrap">
                {NumberFormatter.formatPrice(
                  CostCalculator.getNetPriceOfItem({
                    ...item,
                  }),
                  invoice.currency
                )}
              </td>
              <td className="px-4 py-2 text-sm whitespace-nowrap">
                {NumberFormatter.formatPrice(
                  CostCalculator.getGrossPriceOfItem(
                    {
                      ...item,
                    },
                    countryDetails,
                    clinic || undefined
                  ),
                  invoice.currency
                )}
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={6} />
            <td className="pl-4 py-2 text-sm whitespace-nowrap">
              <div>{`${strings.totalAmount} (${strings.net})`}</div>
              <div>{strings.vatAsTax}</div>
              <div className="font-bold pb-1">{`${strings.totalAmount} (${strings.gross})`}</div>
              <div className="border-t pt-1">{strings.paid}</div>
              <div className="font-bold">{strings.remainingCost}</div>
            </td>
            <td className="pr-4 py-2 text-sm whitespace-nowrap">
              <div className="pl-4">
                {NumberFormatter.formatPrice(
                  invoice.items
                    .map((i: InvoiceItemResponse) => i.price * i.quantity)
                    .reduce((a, b) => a + b, 0),
                  invoice.currency
                )}
              </div>
              <div className="pl-4">
                {NumberFormatter.formatPrice(
                  invoice.totalCost -
                    invoice.items
                      .map((i: InvoiceItemResponse) => i.price * i.quantity)
                      .reduce((a, b) => a + b, 0),
                  invoice.currency
                )}
              </div>
              <div className="font-bold pb-1 pl-4">
                {NumberFormatter.formatPrice(
                  invoice.totalCost,
                  invoice.currency
                )}
              </div>
              <div className="border-t pt-1 pl-4">
                {NumberFormatter.formatPrice(
                  (invoice?.costAccounting?.totalCost || 0) -
                    (invoice?.costAccounting?.remainingCost || 0),
                  invoice?.costAccounting?.currency
                )}
              </div>
              <div className="font-bold pl-4">
                {NumberFormatter.formatPrice(
                  invoice?.costAccounting?.remainingCost || 0,
                  invoice?.costAccounting?.currency
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceItems;
