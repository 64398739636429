/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import CostStatisticsTab from "./CostStatistics";
import ClinicPayments from "./Payment/ClinicPayments";
import ClinicDiscount from "./ClinicDiscount";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";

interface Props {
  clinic: ClinicResponse;
}

const PaymentTab: React.FC<Props> = ({clinic}: Props) => {

  return (
    <div className="w-full md:tw-card md:max-w-4xl space-y-6 md:p-8">
      <div>
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {strings.payments}
        </h2>
      </div>
     <ClinicDiscount clinic={clinic} />
     <ClinicPayments isActiveTab={true} />
     <CostStatisticsTab />
    </div>
  );
};

export default PaymentTab;
