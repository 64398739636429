/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/UserContext";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../../util/LocalStorageVariables";
import { useClinic } from "../../../contexts/ClinicContext";
import { ClinicRole } from "../../../models/clinic/ClinicRole";
import VetRegistrationNumberCard from "./cards/VetRegistrationNumberCard";
import { PinNumberCard } from "./cards/PinNumberCard";
import VetDocumentCard from "./cards/VetDocumentCard";

type ProfileSetupStage = "PINCODE" | "VETREGNUMBER" | "VETDOCUPLOAD";

const OptionalProfileSteps: React.FC = () => {
  const role: UserRole | null = getValueOfActiveUser("role");
  const [remainingSteps, setRemainingSteps] = useState<ProfileSetupStage[]>([
    "PINCODE",
    "VETREGNUMBER",
    "VETDOCUPLOAD",
  ]);
  const [step, setStep] = useState<ProfileSetupStage>("VETREGNUMBER");
  const { user } = useUser();
  const { clinic, clinics } = useClinic();
  const navigate = useNavigate();

  useEffect(() => {
    const states: ProfileSetupStage[] = [];
    const clinicRoles: ClinicRole[] =
      clinics.find((c) => c.clinic.id === clinic?.id)?.roles || [];
    const isVet =
      clinicRoles.includes(ClinicRole.VET) || clinicRoles.length === 0;

    if (!user.hasConfirmedPinCode) {
      states.push("PINCODE");
    }

    if (user.vetRegistrationNumbers.length === 0 && isVet && user.addresses?.[0]?.countryCode === "SE") {
      states.push("VETREGNUMBER");
    }

    if (user?.vetProfession?.state !== "APPROVED" && isVet && user.addresses?.[0]?.countryCode === "SE") {
      states.push("VETDOCUPLOAD");
    }

    setRemainingSteps(states);
  }, [user, clinic, clinics]);

  useEffect(() => {
    if (remainingSteps.length === 0) {
      if (role === "doctor") {
        navigate("/vet");
      } else if (role === "owner") {
        navigate("/pet-owner");
      } else {
        navigate("/");
      }
    } else {
      setStep(remainingSteps[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingSteps]);

  const skip = (st: ProfileSetupStage) => {
    setRemainingSteps([...remainingSteps.filter((s) => s !== st)]);
  };

  switch (step) {
    case "PINCODE":
      return (
        <PinNumberCard
          onSkip={() => {
            skip("PINCODE");
          }}
        />
      );
    case "VETDOCUPLOAD":
      return (
        <VetDocumentCard
          onSuccess={() => {
            skip("VETDOCUPLOAD");
          }}
          onSkip={() => {
            skip("VETDOCUPLOAD");
          }}
        />
      );
    case "VETREGNUMBER":
      return (
        <VetRegistrationNumberCard
          onSuccess={() => {
            skip("VETREGNUMBER");
          }}
          onSkip={() => {
            skip("VETREGNUMBER");
          }}
        />
      );
    default:
      return <></>;
  }
};

export default OptionalProfileSteps;
