/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { MutableRefObject } from "react";
import logger from "../../util/logger";

/*
 * Utility functions to generate dummy audio and video tracks for WebRTC streams.
 * These functions help to fill missing tracks when working with WebRTC connections that require both audio and video tracks.
 */

// Dictionary to store dummy track properties
const dummyTrackRegistry: Map<string, { isDummyTrack: boolean }> = new Map();

// Caches for dummy tracks
const dummyAudioTrackCache: Map<string, MediaStreamTrack> = new Map();
const dummyVideoTrackCache: Map<string, MediaStreamTrack> = new Map();

// Checks if the track is a dummy track
export const isDummyTrack = (track: MediaStreamTrack): boolean => {
  const trackInfo = dummyTrackRegistry.get(track.id);
  return trackInfo ? trackInfo.isDummyTrack : false;
};

export const createDummyAudioTrack = (stopTrack = false): MediaStreamTrack => {
  const cacheKey = "dummyAudioTrack";

  // Check if the dummy audio track already exists in the cache
  const cachedTrack = dummyAudioTrackCache.get(cacheKey);
  if (cachedTrack) {
    return cachedTrack;
  }

  const ctx = new AudioContext();

  // Create an empty buffer to simulate silence
  const buffer = ctx.createBuffer(1, 1, ctx.sampleRate); // 1 sample, silent buffer
  const source = ctx.createBufferSource();
  source.buffer = buffer;

  // Connect the source to a MediaStreamDestination
  const dst = source.connect(ctx.createMediaStreamDestination()) as MediaStreamAudioDestinationNode;

  // Start the source and immediately stop it if needed
  source.start();

  // Immediately stop the track if required
  if (stopTrack) {
    dst.stream.getAudioTracks()[0].stop();
  }

  // Get the first audio track from the media stream
  const track = dst.stream.getAudioTracks()[0];

  // Register the dummy track in the dictionary
  dummyTrackRegistry.set(track.id, { isDummyTrack: true });

  // Cache the track
  dummyAudioTrackCache.set(cacheKey, track);

  // Return the disabled track
  return Object.assign(track, { enabled: false });
};

// Creates a black video track
export const createDummyVideoTrack = (width = 640, height = 480, stopTrack = false): MediaStreamTrack | null => {
  const cacheKey = "dummyVideoTrack";

  // Check if the dummy video track already exists in the cache
  const cachedTrack = dummyVideoTrackCache.get(cacheKey);
  if (cachedTrack) {
    return cachedTrack;
  }

  const canvas = Object.assign(document.createElement("canvas"), { width, height });

  // Get the 2D rendering context
  const ctx = canvas.getContext("2d");

  // Check if the context is not null
  if (ctx) {
    // Fill the canvas with black
    ctx.fillRect(0, 0, width, height);
  } else {
    logger.error("2D context could not be created");
    return null;
  }

  // Capture the canvas content as a media stream
  const stream = canvas.captureStream();

  // Immediately stop the track if required
  if (stopTrack) {
    stream.getVideoTracks()[0].stop();
  }

  // Get the first video track from the media stream
  const track = stream.getVideoTracks()[0];

  // Register the dummy track in the dictionary
  dummyTrackRegistry.set(track.id, { isDummyTrack: true });

  // Cache the track
  dummyVideoTrackCache.set(cacheKey, track);

  // Return the disabled track
  return Object.assign(track, { enabled: false });
};

// Creates a dummy video stream (silent audio and black video)
// Useful for WebRTC configurations where both peers need audio and video tracks
export const createDummyStream = (stopTracks = true): MediaStream => {
  const videoTrack = createDummyVideoTrack(1, 1, stopTracks); // Small dummy video
  const audioTrack = createDummyAudioTrack(stopTracks); // Silent audio

  // Ensure that videoTrack is not null before creating the MediaStream
  if (videoTrack) {
    return new MediaStream([videoTrack, audioTrack]);
  }

  logger.error("Dummy video track could not be created");

  // If video creation failed, return just the audio
  return new MediaStream([audioTrack]);
};

// Fills a given media stream ref with dummy audio and/or video tracks if missing
export const fillWithDummyTracks = (
  streamRef: MutableRefObject<MediaStream | null>,
  stopTracks = false
): MediaStream => {
  // Helper function to update the ref's current value
  const updateRefStream = (newStream: MediaStream) => {
    streamRef.current = newStream;
  };

  // If the stream is missing, create a new stream with dummy tracks
  if (!streamRef.current) {
    const newStream = createDummyStream(stopTracks);
    updateRefStream(newStream);
    return newStream;
  }

  const stream = streamRef.current;

  // Check if the stream already has an audio track, if not, add a dummy audio track
  if (stream.getAudioTracks().length === 0) {
    const audioTrack = createDummyAudioTrack(stopTracks);
    stream.addTrack(audioTrack);
  }

  // Check if the stream already has a video track, if not, add a dummy video track
  if (stream.getVideoTracks().length === 0) {
    const videoTrack = createDummyVideoTrack(640, 480, stopTracks);
    if (videoTrack) {
      stream.addTrack(videoTrack);
    }
  }

  // Ensure the ref's current value is synchronized with the updated stream
  updateRefStream(stream);
  return stream;
};
