/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { MouseEventHandler, ReactElement, useRef, useState } from "react";
import { SpinnerSize } from "../../common/Icons/Spinner";
import LoaderInline from "../../components/LoaderInline";
import { Speaker } from "../../common/Icons/Speaker";
import { combineClassNames, generateRandomName, generateRandomRgbColor } from "../../util/HtmlUtils";
import { MicrophoneOff } from "../../common/Icons/MicrophoneOff";
import { Fullscreen } from "../../common/Icons/Fullscreen";
import { FullscreenExit } from "../../common/Icons/FullscreenExit";
import logger from "../../util/logger";
import HoverChecker from "../../util/HoverChecker";
import { useFullscreen } from "../../hooks/useFullscreen";
import { useVoiceDetector } from "./useVoiceDetector";
import { userProfilePlaceholder } from "../../assets/AssetConfig";
import { calculateProfilePictureSize } from "./Utils";

interface Props {
  callControls: ReactElement;
  extraData?: string;
  videoHeight: number;
}

/*
 * Video component for a test user (useful for layout testing).
 */
export const TestVideo: React.FC<Props> = ({ callControls, extraData, videoHeight }: Props): ReactElement => {
  const [micEnabled, setMicEnabled] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const isVoiceActive = useVoiceDetector(videoRef, micEnabled, false);

  const [name] = useState<string>(generateRandomName());
  const [bgColor] = useState<string>(generateRandomRgbColor());

  const { isFullscreen, enterFullscreen, exitFullscreen } = useFullscreen();

  const toggleFullscreen = async () => {
    if (!containerRef.current) {
      return;
    }

    try {
      if (!isFullscreen) {
        await enterFullscreen(containerRef.current);
      } else {
        await exitFullscreen();
      }
    } catch (error) {
      logger.error("[📹VideoChat] Error toggling fullscreen:", error);
    }
  };

  const handleClick: MouseEventHandler<HTMLElement> = () => {
    setMicEnabled((prevState) => !prevState);
  };

  const handleContextMenu: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    setLoading((prevState) => !prevState);
  };

  const profilePictureSize = calculateProfilePictureSize(videoHeight, 1 / 2);

  return (
    <HoverChecker className="w-full h-full select-none" hoverTimeout={2000}>
      {(_isHovered, isRecentlyHovered) => (
        <div
          className="relative w-full h-full flex justify-center items-center rounded-2xl"
          onClick={handleClick}
          onContextMenu={handleContextMenu}
          ref={containerRef}
          role="button"
          tabIndex={-1}
        >
          {/* Test user video frame */}
          <div
            className={combineClassNames(
              "flex justify-center items-center w-full h-full border-4 rounded-xl",
              micEnabled && isVoiceActive ? "border-blue-600 dark:border-blue-400" : "border-transparent"
            )}
            style={{
              backgroundColor: isLoading ? "gray" : bgColor,
            }}
          >
            {isLoading ? (
              <LoaderInline size={SpinnerSize.VideoChat} />
            ) : (
              <div
                className="absolute"
                style={{
                  width: profilePictureSize.width,
                  height: profilePictureSize.height,
                }}
              >
                <img
                  className="bg-center bg-cover bg-no-repeat bg-white border-gray-100 border-solid dark:border-gray-700 h-full object-cover rounded-full w-full"
                  style={{ borderWidth: "4px" }}
                  alt="Test user"
                  src={userProfilePlaceholder}
                />
              </div>
            )}
          </div>
          {/* User information */}
          <p className="text-white absolute top-5 left-5 bg-gray-500 bg-opacity-40 rounded-full p-2">{name}</p>
          {extraData && (
            <p className="text-white absolute bottom-5 left-5 bg-gray-500 bg-opacity-40 rounded-full p-2">
              {extraData}
            </p>
          )}
          {!micEnabled && (
            <div className="absolute top-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2">
              <MicrophoneOff variant="outline" />
            </div>
          )}
          {micEnabled && isVoiceActive && (
            <div className="absolute top-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2">
              <Speaker />
            </div>
          )}
          <div
            className="absolute bottom-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
            style={{ opacity: isRecentlyHovered ? 1 : 0 }}
            onClick={toggleFullscreen}
            role="button"
            tabIndex={0}
          >
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
          </div>
          {isFullscreen && (
            <div
              className="absolute bottom-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
              style={{ opacity: isRecentlyHovered ? 1 : 0 }}
            >
              {callControls}
            </div>
          )}
        </div>
      )}
    </HoverChecker>
  );
};
