/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CloseButton from "../../../../../components/CloseButton";
import Button from "../../../../../components/Button";
import { roles, strings } from "../../../../../common/Strings/Strings";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import { emailValidation } from "../../../../../util/Validations";
import Select from "../../../../../components/ReactHookFormFields/General/Select/Select";
import AlertBox from "../../../../../components/AlertBox";
import Modal from "../../../../../components/Modal/Modal";
import ClinicEmploymentApi from "../../../../../api/ClinicEmploymentApi";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { ClinicEmploymentType } from "../../../../../models/employment/ClinicEmploymentType";
import { Option } from "../../../../../models/Option";
import { collaboratorGroupTitles } from "../CollaboratorsTab";

interface Props {
  clinicId: string;
  getClinic(): void;
  isActiveTab: boolean;
  loading: boolean;
  onHide(): void;
  setLoading(loading: boolean): void;
  setSelectedEmployeeType(type: ClinicEmploymentType): void;
  show: boolean;
}

interface Form {
  contact: string;
  type: ClinicEmploymentType;
}

const AddCollaboratorModal: React.FC<Props> = ({
  clinicId,
  getClinic,
  isActiveTab,
  loading,
  onHide,
  setLoading,
  setSelectedEmployeeType,
  show,
}: Props) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [alertMessage, setAlertMessage] = useState<
    string | null | ReactElement
  >(null);

  useEffect(() => {
    if (!isActiveTab) return;

    const initOptions: Array<Option> = [];

    Object.keys(collaboratorGroupTitles).forEach((type: string) => {
      const item = { value: type, title: roles[type] };
      initOptions.push(item);
    });

    setOptions(initOptions);
  }, [isActiveTab]);

  const onClose = () => {
    setAlertMessage(null);
    reset();
    onHide();
  }

  const onSubmit = async ({ contact, type }: Form) => {
    setAlertMessage(null);
    setSelectedEmployeeType(type);
    setLoading(true);
    const request = { email: contact, type };

    try {
      await ClinicEmploymentApi.addClinicEmployee(clinicId, request);
      getClinic();
      onClose();
    } catch (err) {
      setAlertMessage(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<Form>({
    mode: "onChange",
  });

  return (
    <Modal
      handleClose={onClose}
      show={show}
    >
      <Modal.Header title={strings.addCollab} />
      <Modal.Body noSpacing>
        <form
          className="modal-body"
          id="newEmployeeForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Field
            name="contact"
            register={register}
            autoComplete={AutoCompleteOptions.off}
            error={errors.contact}
            fieldOptions={{
              pattern: {
                value: emailValidation.matchRegexp,
                message: strings.emailNotValid,
              },
            }}
            label={strings.emailAddress}
            type="email"
            required
          />
          <Select
            name="type"
            register={register}
            options={options}
            error={errors.type}
            fieldOptions={{ required: true }}
            label={strings.position}
          />
          <AlertBox message={alertMessage} className="mt-5" />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid}
          form="newEmployeeForm"
          loading={loading}
          type="submit"
        >
          {strings.addNew}
        </Button>
        <CloseButton onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default AddCollaboratorModal;
