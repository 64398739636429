/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { CountrySettingsResponse } from "../../../models/management/CountrySettingsResponse";
import CountryApi from "../../../api/CountryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { positiveOrZeroTwoDecimal } from "../../../util/Validations";
import { CountryUpdateRequest } from "../../../models/management/CountryUpdateRequest";
import Button from "../../../components/Button";
import PricesSection from "./PricesSection";
import Switch2 from "../../../components/ReactHookFormFields/General/Switch2";

interface Props {
  isActiveTab: boolean;
  countryCode: string;
}

const defaultFormValues = (country?: CountrySettingsResponse, globalvetEnabled?: boolean) => {
  const defaultValues: CountryUpdateRequest = {
    globalvetEnabled: globalvetEnabled || false,
  };

  defaultValues.minimalChargeValue = country?.minimalChargeValue;
  defaultValues.trialDiscountPercent = country?.trialDiscountPercent;
  defaultValues.trialPeriodDays = country?.trialPeriodDays;
  defaultValues.trialEndReminderBeforeDays = country?.trialEndReminderBeforeDays;

  return defaultValues;
};

const FeeSection = ({ isActiveTab, countryCode }: Props): ReactElement => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [globalvetEnabled, setGlobalvetEnabled] = useState(false);
  const [country, setCountry] = useState<CountrySettingsResponse>();
  const [countryDetails, setCountryDetails] = useState<CountryDetailsResponse>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.ERROR);

  useEffect(() => {
    if (!isActiveTab) return;

    const getCountrySettings = async () => {
      try {
        const resp = await CountryApi.getCountrySettingsByCode(countryCode);
        setGlobalvetEnabled(resp.data?.globalvetEnabled);
        setCountry(resp.data);
        setIsLoaded(true);
      } catch (err) {
        setError(await getGeneralError(err));
      }
    };

    void getCountrySettings();
  }, [countryCode, isActiveTab]);

  useEffect(() => {
    const getCountryDetails = async () => {
      try {
        const response = await CountryApi.getCountryDetails(countryCode);
        setCountryDetails(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      }
    };

    void getCountryDetails();
  }, [countryCode]);

  const submitFees = async (request: CountryUpdateRequest) => {
    if (!country) return;

    setAlertMessage(null);
    setLoading(true);

    try {
      const response = await CountryApi.updateCountry(country.id, request);
      setCountry(response.data);
      setGlobalvetEnabled(response.data.globalvetEnabled);
      setAlertMessage(strings.savedSuccessfully);
      setAlertType(AlertType.SUCCESS);
    } catch (err) {
      setAlertMessage(await getGeneralError(err));
      setAlertType(AlertType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<CountryUpdateRequest>({
    mode: "onChange",
    defaultValues: defaultFormValues(country),
  });

  useEffect(() => {
    reset(defaultFormValues(country, globalvetEnabled));
  }, [country, globalvetEnabled, reset]);

  return (
    <>
      <LoaderInline className="my-3" hidden={isLoaded} />
      <div hidden={!isLoaded || error == null}>
        <AlertBox message={error} className="my-3" />
      </div>
      <div hidden={!isLoaded || error != null}>
        <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0">
          {country && countryDetails && (
            <div className="w-full md:max-w-md space-y-6">
              <div className="tw-card">
                <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
                  {strings.settings}
                </h2>
                <form className="space-y-6 p-4">
                  <Switch2
                    control={control}
                    defaultChecked={globalvetEnabled}
                    label={strings.serviceEnabledForCountry}
                    name="globalvetEnabled"
                  />
                  <Field
                    className="w-50"
                    name="minimalChargeValue"
                    label={strings.minimalChargeValue}
                    fieldOptions={{
                      pattern: {
                        value: positiveOrZeroTwoDecimal.matchRegexp,
                        message: strings.notAllowedCharacter,
                      },
                    }}
                    register={register}
                    autoComplete={AutoCompleteOptions.off}
                    suffix={countryDetails?.currency}
                    error={errors.minimalChargeValue}
                  />
                  <Field
                    className="w-50"
                    name="trialDiscountPercent"
                    label={strings.trialDiscountPercent}
                    placeholder=""
                    register={register}
                    autoComplete={AutoCompleteOptions.off}
                    suffix="%"
                    error={errors.trialDiscountPercent}
                  />
                  <Field
                    className="w-50"
                    name="trialPeriodDays"
                    label={strings.trialPeriodDays}
                    placeholder=""
                    register={register}
                    autoComplete={AutoCompleteOptions.off}
                    suffix={strings.days}
                    error={errors.trialPeriodDays}
                  />
                  <Field
                    className="w-50"
                    name="trialEndReminderBeforeDays"
                    label={strings.trialEndReminderBeforeDays}
                    placeholder=""
                    type="number"
                    register={register}
                    autoComplete={AutoCompleteOptions.off}
                    suffix={strings.daysBefore}
                    error={errors.trialEndReminderBeforeDays}
                  />
                  <AlertBox message={alertMessage} type={alertType} />
                  <Button
                    disabled={!isValid || !isDirty || loading}
                    loading={loading}
                    onClick={handleSubmit(submitFees)}
                    variant="primary"
                  >
                    {strings.save}
                  </Button>
                </form>
              </div>
              <PricesSection isActiveTab={isActiveTab} country={country} countryDetails={countryDetails} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FeeSection;
