/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { ClinicDiscountResponse } from "../../../models/discount/ClinicDiscountResponse";
import { TrialDiscountResponse } from "../../../models/discount/TrialDiscountResponse";
import Tag, { TagType } from "../../../components/Tag";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import moment from "moment/moment";
import Button from "../../../components/Button";
import { Trash } from "../../../common/Icons/Trash";

interface Props {
  clinicDiscount?: ClinicDiscountResponse;
  trialDiscount?: TrialDiscountResponse;
  clinicDiscountClicked?: () => void;
  trialDiscountClicked?: () => void;
  clinicDiscountDeleteClicked?: () => void;
  trialDiscountDeleteClicked?: () => void;
}

export const getDiscountType = (trialDiscount?: TrialDiscountResponse, clinicDiscount?: ClinicDiscountResponse) => {
  if (trialDiscount) {
    return (
      <Tag
        type={TagType.primary}
        text={`${trialDiscount.trialDiscountPercent}%`}
      />
    );
  }
  if (clinicDiscount?.isFree) {
    return <Tag type={TagType.primary} text={strings.discountFree} />;
  }
  return <Tag type={TagType.primaryOutlined} text={strings.discountCustom} />;
};

const DiscountCard = (props: Props): ReactElement => {
  const {
    clinicDiscount,
    trialDiscount,
    clinicDiscountClicked,
    trialDiscountClicked,
    clinicDiscountDeleteClicked,
    trialDiscountDeleteClicked,
  } = props;

  const validityStart =
    clinicDiscount?.validityStart || trialDiscount?.validityStart;
  const validityEnd = clinicDiscount?.validityEnd || trialDiscount?.validityEnd;

  const onClick = () => {
    if (trialDiscount && trialDiscountClicked) {
      trialDiscountClicked();
    }
    if (clinicDiscount && clinicDiscountClicked) {
      clinicDiscountClicked();
    }
  };

  const onDeleteClick = () => {
    if (trialDiscount && trialDiscountDeleteClicked) {
      trialDiscountDeleteClicked();
    }
    if (clinicDiscount && clinicDiscountDeleteClicked) {
      clinicDiscountDeleteClicked();
    }
  };

  return (
    <div className="flex flex-col justify-between tw-card shadow h-full">
      <div className="p-4 border-gray-200 dark:border-gray-700" role="button">
        <div className="flex">
          <div className="flex-grow" onClick={onClick}>
            {getDiscountType()}
          </div>
          <div>
            <Button variant="icon" onClick={onDeleteClick} small>
              <Trash />
            </Button>
          </div>
        </div>
        <div className="text-sm font-medium pt-2" onClick={onClick}>
          {moment(validityStart).format(dateAndTime.momentDateFormat)} -{" "}
          {validityEnd
            ? moment(validityEnd).format(dateAndTime.momentDateFormat)
            : strings.untilWithdrawn}
        </div>
      </div>
    </div>
  );
};

export default DiscountCard;
