/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced; copied; distributed; disseminated to the public; adapted or transmitted
 * in any form or by any means; including photocopying; recording; or other electronic or
 * mechanical methods; without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { MutableRefObject, ReactElement, RefObject } from "react";
import moment, { Duration } from "moment";
import { DataConnection, MediaConnection, Peer } from "peerjs";
import logger from "../../util/logger";
import { VideoChatUser } from "../../models/videoConsultation/VideoChatUser";
import { strings } from "../../common/Strings/Strings";
import joinSoundEffectDefault from "../../assets/audio/join_sound_effect.wav";
import joinSoundEffectAlternative from "../../assets/audio/join_sound_effect_2.wav";
import leaveSoundEffectDefault from "../../assets/audio/leave_sound_effect.wav";
import leaveSoundEffectAlternative from "../../assets/audio/leave_sound_effect_2.wav";
import { getElementHeightById } from "../../util/HtmlUtils";
import Params from "../../common/Params";
import { VideoConsultationRole } from "../../models/videoConsultation/VideoConsultationRole";
import { ConsultationTime } from "./FinishConsultationModal";
import { ClinicFeeUnit } from "../../models/clinic/ClinicFeeUnit";
import { ClinicFeeResponse } from "../../models/clinic/ClinicFeeResponse";
import { ConnectionsPayload, StreamPayload } from "./remoteUsersReducer";
import Tooltip from "../../components/Tooltip";
import { fillWithDummyTracks, isDummyTrack } from "./DummyStream";

export interface MediaFeatures {
  hasMicrophone: boolean;
  hasMicrophonePermission: boolean;
  hasWebcam: boolean;
  hasWebcamPermission: boolean;
}

export interface MediaErrors {
  micError: boolean;
  camError: boolean;
}

export interface MediaConfig {
  camEnabled: boolean;
  micEnabled: boolean;
  camMissing: boolean;
  micMissing: boolean;
}

export interface AudioVideoToggleData {
  camEnabled: boolean;
  micEnabled: boolean;
}

export interface VideoChatUserConnection {
  dataConnection?: DataConnection;
  id: string; // The ID of the remote peer / connection
  mediaConfig?: MediaConfig;
  mediaConnection?: MediaConnection;
  stream?: MediaStream | null;
  user?: VideoChatUser;
}

export enum LoadState {
  "MISSING" = "Missing",
  "INIT" = "Init",
  "LOADING" = "Loading",
  "LOADED" = "Loaded",
}

interface MediaDebugProps {
  audioTracks: number;
  camEnabled: boolean;
  camMissing: boolean;
  liveAudioTracks: number;
  liveVideoTracks: number;
  micEnabled: boolean;
  micMissing: boolean;
  placeholderLoadState: LoadState;
  streamId: string;
  audioIsLive: boolean;
  audioIsMuted?: boolean | "Unknown";
  audioIsPaused?: boolean | "Unknown";
  audioReadyState?: number | "Unknown";
  videoIsLive: boolean;
  videoIsMuted: boolean | "Unknown";
  videoIsPaused: boolean | "Unknown";
  videoLoadState: LoadState;
  videoReadyState: number | "Unknown";
  videoTracks: number;
}

interface MediaTrackProperties {
  id: string;
  kind: string;
  label: string;
  enabled: boolean;
  muted: boolean;
  readyState: string;
  constraints?: MediaTrackConstraints;
  settings?: MediaTrackSettings;
  capabilities?: MediaTrackCapabilities;
}

interface MediaStreamProperties {
  id: string;
  active: boolean;
  audioTracks: MediaTrackProperties[];
  videoTracks: MediaTrackProperties[];
}

// [For debug] Returns a MediaStream information object
// noinspection JSUnusedGlobalSymbols
export const getMediaStreamProperties = (stream: MediaStream | null | undefined): MediaStreamProperties | null => {
  if (!stream) {
    return null;
  }

  const audioTracks = stream.getAudioTracks().map((track) => ({
    id: track.id,
    kind: track.kind,
    label: track.label,
    enabled: track.enabled,
    muted: track.muted,
    readyState: track.readyState,
    constraints: track.getConstraints ? track.getConstraints() : undefined,
    settings: track.getSettings ? track.getSettings() : undefined,
    capabilities: track.getCapabilities ? track.getCapabilities() : undefined,
  }));

  const videoTracks = stream.getVideoTracks().map((track) => ({
    id: track.id,
    kind: track.kind,
    label: track.label,
    enabled: track.enabled,
    muted: track.muted,
    readyState: track.readyState,
    constraints: track.getConstraints ? track.getConstraints() : undefined,
    settings: track.getSettings ? track.getSettings() : undefined,
    capabilities: track.getCapabilities ? track.getCapabilities() : undefined,
  }));

  return {
    id: stream.id,
    active: stream.active,
    audioTracks,
    videoTracks,
  };
};

/**
 * Checks if the user manually denied the permissions (this should work in most browsers)
 * Note: A side effect is that this opens a prompt (asking for microphone or camera permission)
 * Note2: This should only run if the user allows only either microphone or camera
 */
async function isMediaDenied(media: "audio" | "video") {
  try {
    // Convert the media type to the corresponding permission name
    const permissionName = media === "audio" ? "microphone" : "camera";
    let permissionStatus;

    // Check if the Permissions API is available
    if (navigator.permissions && navigator.permissions.query) {
      try {
        permissionStatus = await navigator.permissions.query({
          name: permissionName as PermissionName,
        });
      } catch (error) {
        console.warn("Permissions API query failed, falling back to getUserMedia.", error);
      }
    }

    // If the Permissions API is available and the state is 'denied', return true
    if (permissionStatus && permissionStatus.state === "denied") {
      return true;
    }

    // Fallback to using getUserMedia if Permissions API is not available or does not return 'denied'
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        [media]: true,
      });
      stream.getTracks().forEach((track) => track.stop());
      return false;
    } catch (error) {
      return true;
    }
  } catch (error) {
    // Assume permission is denied if an error occurs
    return true;
  }
}

export type RequestMedia = "audio" | "video" | "all";

// Global registry for tracking media streams
export const mediaStreamRegistry = {
  streams: new Map(), // Map of streamId -> stream object
  addStream(stream: MediaStream): void {
    const streamId = stream.id;
    if (!this.streams.has(streamId)) {
      this.streams.set(streamId, stream);
    }
  },
  removeStream(stream?: MediaStream | null): void {
    if (!stream) {
      return;
    }
    const streamId = stream.id;
    if (this.streams.has(streamId)) {
      stream.getTracks().forEach((track) => track.stop());
      this.streams.delete(streamId);
    }
  },
  listStreams(): MediaStream[] {
    return Array.from(this.streams.values());
  },
};

/**
 * Sets up the video stream of the local user's camera
 * Optionally add a stream ref if it's already been acquired
 * Returns MediaData if successful or null if the media could not be acquired (e.g. permission is denied)
 */
export const setupUserVideo = async (
  videoRef: RefObject<HTMLVideoElement | undefined>,
  mediaFeatures: MediaFeatures,
  mediaConfig: MediaConfig,
  requestMedia: "audio" | "video" | "all",
  setMediaError?: (media: "audio" | "video" | "clearAll" | "clearForAudio" | "clearForVideo", error?: any) => void,
  setMediaPermissionError?: (
    media: "audio" | "video" | "clearAll" | "clearForAudio" | "clearForVideo",
    error?: any
  ) => void,
  streamRef?: RefObject<MediaStream | null>,
  retryWithSingleMedia = true
): Promise<MediaStream | null> => {
  // Reset the errors
  if (requestMedia === "all") {
    setMediaError?.("clearAll");
    setMediaPermissionError?.("clearAll");
  }
  if (requestMedia === "audio") {
    setMediaError?.("clearForAudio");
    setMediaPermissionError?.("clearForAudio");
  }
  if (requestMedia === "video") {
    setMediaError?.("clearForVideo");
    setMediaPermissionError?.("clearForVideo");
  }

  let stream: MediaStream | null = null;

  // If there are no media devices, nothing should be requested
  if (!mediaFeatures.hasMicrophone && !mediaFeatures.hasWebcam) {
    return null;
  }

  let micDenied = false;
  let camDenied = false;

  // If one of the permissions are missing, check which one
  // This should only happen if the user manually denied one of them
  if (
    mediaFeatures.hasMicrophone &&
    mediaFeatures.hasWebcam &&
    mediaFeatures.hasMicrophonePermission !== mediaFeatures.hasWebcamPermission
  ) {
    micDenied = await isMediaDenied("audio");
    camDenied = await isMediaDenied("video");
  }

  const shouldRequestAudio = requestMedia === "video" ? false : mediaFeatures.hasMicrophone && !micDenied;
  const shouldRequestVideo = requestMedia === "audio" ? false : mediaFeatures.hasWebcam && !camDenied;

  try {
    // If the user media has not been acquired yet, get the user media
    if (!streamRef || !streamRef.current) {
      stream = await navigator.mediaDevices.getUserMedia({
        video: shouldRequestVideo
          ? {
              width: { ideal: 4096 },
              height: { ideal: 2160 },
            }
          : undefined,
        audio: shouldRequestAudio,
      });

      // Add the new stream to the registry
      mediaStreamRegistry.addStream(stream);
    } else {
      stream = streamRef.current;
    }

    if (videoRef.current && stream) {
      const videoElement = videoRef.current;
      if ("srcObject" in videoRef.current) {
        videoElement.srcObject = stream;
      } else {
        // Fallback for older browsers
        videoElement.src = window.URL.createObjectURL(stream as any);
      }
    }
  } catch (error: any) {
    // Check common WebRTC error types
    if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
      // Set the permission error for the requested media
      if (setMediaPermissionError) {
        shouldRequestAudio && setMediaPermissionError("audio", error);
        shouldRequestVideo && setMediaPermissionError("video", error);
      }

      logger.error("[📹VideoChat] Permission denied for accessing user media:", error);
    } else if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError") {
      logger.error("[📹VideoChat] No microphone or webcam found or available:", error);
    } else {
      logger.error("[📹VideoChat] Error accessing user media:", error);

      // If both media devices were requested
      if (shouldRequestAudio && shouldRequestVideo) {
        // Set the error for the newly requested media -> Audio
        if (mediaConfig.camEnabled && !mediaConfig.micEnabled) {
          setMediaError?.("audio", error);
        }

        // Set the error for the newly requested media -> Video
        if (mediaConfig.micEnabled && !mediaConfig.camEnabled) {
          setMediaError?.("video", error);
        }

        // Retry with a single media device if both were requested
        // We only retry with the microphone to avoid complications, as users typically use either the mic or both
        if (retryWithSingleMedia) {
          logger.info("[📹VideoChat] Retrying setting up user video with only microphone...");
          return setupUserVideo(
            videoRef,
            mediaFeatures,
            mediaConfig,
            "audio",
            setMediaError,
            setMediaPermissionError,
            streamRef
          );
        }

        return stream;
      }

      // Set the error for the requested media when only one media device was requested
      if (setMediaError) {
        shouldRequestAudio ? setMediaError("audio", error) : setMediaError("video", error);
      }
      return null;
    }
  }

  return stream;
};

export interface VideoLayout {
  area: number;
  cols: number;
  rows: number;
  width: number;
  height: number;
  visibleVideos: number;
  remainingVideos: number;
}

/**
 * Recalculates the optimal layout for a given number of videos within a specified container element,
 * ensuring that the layout maximizes the total area used while respecting the provided aspect ratio constraints,
 * the gap between videos, and the horizontal padding of the container.
 *
 * This function iterates through potential column configurations to find the one that allows for the
 * highest total area of video elements while maintaining the specified minimum and maximum aspect ratios
 * for individual videos, and accounting for gaps and padding in the container.
 *
 * @param {number} containerWidth - The width of the container in pixels.
 * @param {number} containerHeight - The height of the container in pixels.
 * @param {number} videoCount - The total number of videos to layout.
 * @param {number} [gap=0] - The gap between videos in pixels.
 * @param {number} [paddingX=0] - The horizontal padding inside the container in pixels.
 * @param {number} [minWidth=200] - The minimum width of a video in pixels.
 * @param {number} [minHeight=200] - The minimum height of a video in pixels.
 * @param {number} [minAspectRatio=1] - The minimum aspect ratio (width/height) of a video.
 * @param {number} [maxAspectRatio=16/9] - The maximum aspect ratio (width/height) of a video.
 * @returns {VideoLayout} The best layout configuration for the videos.
 */
export const recalculateLayout = (
  containerWidth: number,
  containerHeight: number,
  videoCount: number,
  gap = 0,
  paddingX = 0,
  minWidth = 200,
  minHeight = 200,
  minAspectRatio = 1,
  maxAspectRatio = 16 / 9
): VideoLayout => {
  // Initialize the best layout with default values
  let bestLayout: VideoLayout = {
    area: 0,
    cols: 0,
    rows: 0,
    width: 0,
    height: 0,
    visibleVideos: 0,
    remainingVideos: 0,
  };

  const availableWidth = containerWidth - paddingX * 2;
  const availableHeight = containerHeight;

  // Special case: If the container is smaller than the minimum size
  if (availableWidth < minWidth || availableHeight < minHeight) {
    const height = availableHeight; // Video height is fixed to container height
    let width = Math.floor((availableWidth - (videoCount - 1) * gap) / videoCount);

    // Adjust width to respect aspect ratio constraints
    const aspectRatio = width / height;
    if (aspectRatio < minAspectRatio) {
      width = Math.floor(height * minAspectRatio);
    } else if (aspectRatio > maxAspectRatio) {
      width = Math.floor(height * maxAspectRatio);
    }

    // Calculate how many videos can fit in a single row
    const cols = Math.max(1, Math.floor((availableWidth + gap) / (width + gap)));
    const visibleVideos = Math.min(videoCount, cols);
    const remainingVideos = videoCount - visibleVideos;

    return {
      area: width * height * visibleVideos,
      cols,
      rows: 1, // Always one row in this special case
      width,
      height,
      visibleVideos,
      remainingVideos,
    };
  }

  let currentVideoCount = videoCount;

  /**
   * Helper function to calculate and validate a layout.
   *
   * @param {number} cols - The number of columns in the grid.
   * @param {number} rows - The number of rows in the grid.
   * @returns {VideoLayout | null} A valid layout configuration or null if constraints are not met.
   */
  const calculateLayout = (cols: number, rows: number): VideoLayout | null => {
    // Calculate available dimensions for the videos
    let availableWidth = containerWidth - paddingX * 2 - (cols - 1) * gap;
    let availableHeight = containerHeight - (rows - 1) * gap;

    // Prevent negative available width/height
    if (availableWidth < 0) availableWidth = 0;
    if (availableHeight < 0) availableHeight = 0;

    let width = Math.floor(availableWidth / cols);
    let height = Math.floor(availableHeight / rows);

    // Adjust dimensions to fit within aspect ratio constraints
    const aspectRatio = width / height;
    if (aspectRatio < minAspectRatio) {
      height = Math.floor(width / minAspectRatio);
    } else if (aspectRatio > maxAspectRatio) {
      width = Math.floor(height * maxAspectRatio);
    }

    // Ensure dimensions meet minimum size requirements
    if (width < minWidth || height < minHeight) {
      return null;
    }

    // Calculate the total area covered by the layout
    const area = width * height * cols * rows;

    return {
      area,
      cols,
      rows,
      width,
      height,
      visibleVideos: cols * rows,
      remainingVideos: Math.max(0, videoCount - cols * rows),
    };
  };

  // Try all possible column counts to find the best layout
  while (currentVideoCount > 0) {
    for (let cols = 1; cols <= currentVideoCount; cols += 1) {
      const rows = Math.ceil(currentVideoCount / cols);

      // Attempt to calculate a valid layout for the given column and row count
      const layout = calculateLayout(cols, rows);

      // Update the best layout if the current one has a larger usable area
      if (layout && layout.area > bestLayout.area) {
        bestLayout = layout;
      }
    }

    // If a valid layout is found, exit the loop early
    if (bestLayout.area > 0) {
      break;
    }

    // Reduce the video count and retry if no valid layout is found
    currentVideoCount -= 1;
  }

  return bestLayout;
};

export const reserveCollectorCardSlot = (layout: VideoLayout): VideoLayout => {
  const { visibleVideos, remainingVideos } = layout;

  // If no remaining videos, return the layout as is
  if (remainingVideos === 0) {
    return layout;
  }

  // Reserve one slot for the collector card
  return {
    ...layout,
    visibleVideos: visibleVideos - 1, // Reduce visible videos by 1 for the collector card
    remainingVideos: remainingVideos + 1, // Add 1 to remaining videos since one video is now "hidden"
  };
};

export const calculateProfilePictureSize = (
  videoHeight: number,
  scalingFactor: number,
  minSize = 20,
  maxSize = 200
): { width: number; height: number } => {
  // Calculate the scaled size
  const scaledSize = videoHeight * scalingFactor;

  // Clamp the size within the min and max bounds
  const clampedSize = Math.min(Math.max(scaledSize, minSize), maxSize);

  // Return the width and height as the same clamped size
  return {
    width: clampedSize,
    height: clampedSize,
  };
};

export const formatMediaConfig = (mediaConfig: MediaConfig) => {
  const camStatus = mediaConfig.camEnabled ? "✅Enabled" : "❌Disabled";
  const camMissingStatus = mediaConfig.camMissing ? "⚠️Missing" : "✅Present";

  const micStatus = mediaConfig.micEnabled ? "✅Enabled" : "❌Disabled";
  const micMissingStatus = mediaConfig.micMissing ? "⚠️Missing" : "✅Present";

  return `Camera: ${camStatus} ${camMissingStatus} | Microphone: ${micStatus} ${micMissingStatus}`;
};

export const getFormattedTimestamp = () => `[${moment().format("HH:mm:ss.SSS")}]`;

// Creates a new peer that represents the local user
// Uses the auto generated ID for the peer id, custom ID can cause problems with some special characters
export const createPeer = (): Peer => {
  // Extract the hostname from the URL
  const url = new URL(Params.videoChatSignalServiceBaseURL);
  const isLocalhost = url.hostname.includes("localhost");

  let peer;

  if (isLocalhost) {
    // Uses the locally hosted peer server (from videochat-signal-service)
    peer = new Peer({
      host: url.hostname,
      port: 9000,
      secure: false,
    });
  } else {
    // Uses the official PeerServer Cloud for staging/production
    // (since the GlobalVET peer server only works for localhost right now)
    peer = new Peer();
  }

  return peer;
};

export interface LocalConfig {
  mediaConfig: MediaConfig;
  peerRef: MutableRefObject<Peer | undefined>;
  streamRef: MutableRefObject<MediaStream | null>;
  user: VideoChatUser;
}

export interface CallInfo {
  mediaConfig: MediaConfig;
  user: VideoChatUser;
}

interface CallConstraints {
  mandatory: {
    OfferToReceiveAudio: boolean;
    OfferToReceiveVideo: boolean;
  };
  offerToReceiveAudio: number;
  offerToReceiveVideo: number;
}

interface CallOptions {
  constraints: CallConstraints;
  sdpTransform: (sdp: string) => string;
}

// TODO: Update comment
/**
 * Create an initiator peer.
 * For every P2P connection (which is one of the edges in the topology) one of the two peers is the initiator.
 * Used to create peers who are already in the video chat room.
 * These peers (or one of the peers) initiate the connection.
 * They emit the signal first, this way this object representing the remote peer will initiate the connection when joining the room.
 */
export const callUser = (
  userToCall: VideoChatUser,
  { mediaConfig, peerRef, streamRef, user }: LocalConfig,
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  addUserConnections: (payload: ConnectionsPayload) => void,
  addUserStream: (payload: StreamPayload) => void
): VideoChatUserConnection => {
  const peer = peerRef.current;

  if (!peer) {
    throw new Error("[📹VideoChat] Peer is missing!");
  }

  // Connect to the remote peer (data connection)
  const dataConnection = peer.connect(userToCall.peerId);

  // This options object should be given to the call function
  // This makes asymmetric streams (streams with different kinds and number of tracks) work properly between peers
  const options: CallOptions = {
    constraints: {
      mandatory: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      },
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1,
    },
    sdpTransform: (sdp: string) =>
      sdp.replace(
        "a=fmtp:111 minptime=10;useinbandfec=1",
        "a=fmtp:111 ptime=5;useinbandfec=1;stereo=1;maxplaybackrate=48000;maxaveragebitrate=128000;sprop-stereo=1"
      ),
  };

  // Call the peer (media connection)
  const mediaConnection = peer.call(userToCall.peerId, fillWithDummyTracks(streamRef), options);

  // Update the stream of the videoRef
  if (videoRef.current) {
    videoRef.current.srcObject = streamRef.current;
  }

  mediaConnection.on("stream", (remoteStream: MediaStream) => {
    addUserStream({
      stream: remoteStream,
      peerId: mediaConnection.peer,
    });
  });

  // Create the callerInfo (local user info)
  const callerInfo: CallInfo = {
    mediaConfig,
    user,
  };

  // Send the local user's state to the callee after successful data connection
  dataConnection.on("open", () => {
    dataConnection.send(JSON.stringify(callerInfo));
  });

  // Process the remote user's state
  // The first time the caller (local user) receives data is instantly after a successful connection
  // Only handle the data once, the RemoteVideo component will handle subsequent data event (e.g. mic/cam toggle)
  dataConnection.once("data", (data: any) => {
    const callInfo: CallInfo = JSON.parse(data.toString());

    addUserConnections({
      dataConnection,
      mediaConnection,
      user: { ...callInfo.user, roles: userToCall.roles },
      mediaConfig: callInfo.mediaConfig,
    });

    logger.info(
      `[📹VideoChat] ${callInfo.user.name} has joined the room. Remote user media config: ${formatMediaConfig(
        callInfo.mediaConfig
      )}.`
    );
  });

  // Handle data connection error
  dataConnection.on("error", (error) => {
    logger.error(`[📹VideoChat] Data connection error: ${error}`);
  });

  // Handle media connection error
  mediaConnection.on("error", (error) => {
    logger.error(`[📹VideoChat] Media connection error: ${error}`);
  });

  logger.info(`[📹VideoChat] Calling ${userToCall.name}...`);

  return {
    id: userToCall.id,
    dataConnection,
    mediaConnection,
    user: userToCall,
  };
};

/**
 * Create a peer (who is not an initiator).
 * For every P2P connection (which is one of the edges in the topology) one of the two peers is not the initiator.
 * Used to create peers who are arriving to the video chat room where the local user is already present.
 * They respond to the initiator peers.
 * They answer (emit back) the signal, this way this object representing the arriving remote peer will respond to the connection request.
 */
export const answerCall = (
  callerUser: MutableRefObject<VideoChatUserConnection>,
  { mediaConfig, peerRef, streamRef, user }: LocalConfig
): void => {
  const peer = peerRef.current;
  const stream = streamRef.current;

  if (!peer) {
    throw new Error("[📹VideoChat] Peer is missing!");
  }

  if (!stream) {
    throw new Error("[📹VideoChat] Stream is is missing!");
  }

  // Create the callee info (local user info)
  const calleeInfo = {
    mediaConfig: {
      camEnabled: mediaConfig.camEnabled,
      micEnabled: mediaConfig.micEnabled,
      camMissing: mediaConfig.camMissing,
      micMissing: mediaConfig.micMissing,
    },
    user,
  };

  // Send the local user's state to the caller user after successful data connection
  peer.on("connection", (connection: DataConnection) => {
    connection.on("open", () => {
      // Assign the connection
      callerUser.current.dataConnection = connection;

      // Send the data
      connection.send(calleeInfo);
      logger.info(`[📹VideoChat] ${getFormattedTimestamp()} Connected to user ?.`);
    });
  });

  logger.info(`[📹VideoChat] Peer successfully created for newly arriving user: ${user.name}.`);
};

// Some browsers (Like Firefox) have different autoplay policies
// Firefox only allows muted videos to autoplay by default
// This will try to autoplay the video of the stream if it becomes paused
export const playVideo = (videoRef: React.MutableRefObject<HTMLVideoElement | null>, local: boolean) => {
  const video = videoRef.current;
  const videoSource = video?.srcObject as MediaStream;

  if (video && videoSource) {
    // Check if the video is already playing or is ready to play
    const isPlaying =
      video.currentTime > 0 && !video.paused && !video.ended && video.readyState === video.HAVE_ENOUGH_DATA;

    if (!isPlaying && video.readyState === video.HAVE_ENOUGH_DATA) {
      // Mute video for autoplay compliance in some browsers
      video.muted = true;

      const playPromise = video.play();

      if (playPromise) {
        playPromise
          .then(() => {
            // Unmute the remote video after successfully starting the play
            if (!local) {
              video.muted = false;
            }
          })
          .catch((error) => {
            console.error("Error attempting to play video:", error);
          });
      }
    }
  }
};

export const copyAddressToClipboard = () => {
  void navigator.clipboard.writeText(window.location.href);
};

export const usersToNameArray = (users: VideoChatUser[]) => users.map((user) => user.name);

type TrackType = "video" | "audio" | "all";

export const getMediaTracks = (
  trackType: TrackType,
  mediaStream?: MediaStream | null,
  onlyLive = false,
  onlyEnabled = false
): MediaStreamTrack[] => {
  if (!mediaStream) {
    return [];
  }

  // Helper function to filter tracks based on the `onlyLive` and `onlyEnabled` options
  const filterTracks = (tracks: MediaStreamTrack[]) =>
    tracks.filter((t) => {
      const isLive = !onlyLive || t.readyState === "live";
      const isEnabled = !onlyEnabled || t.enabled;
      return isLive && isEnabled;
    });

  if (trackType === "audio") {
    const audioTracks = mediaStream.getAudioTracks();
    return filterTracks(audioTracks);
  }

  if (trackType === "video") {
    const videoTracks = mediaStream.getVideoTracks();
    return filterTracks(videoTracks);
  }

  const tracks = mediaStream.getTracks();
  return filterTracks(tracks);
};

export const getMediaTracksForVideo = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  trackType: TrackType,
  onlyLive = false,
  onlyEnabled = false
): MediaStreamTrack[] => {
  const videoElement = videoRef.current;

  if (!videoElement) {
    return [];
  }

  const mediaStream = videoElement.srcObject as MediaStream | null;

  return getMediaTracks(trackType, mediaStream, onlyLive, onlyEnabled);
};

export const stopMediaTracks = (mediaStream: MediaStream | null, trackType: TrackType = "all"): void => {
  if (!mediaStream) {
    return;
  }

  const tracks = getMediaTracks(trackType, mediaStream);

  tracks.forEach((track) => {
    track.stop();
  });
};

export const stopMediaTracksForVideo = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  trackType: TrackType = "all"
): void => {
  const videoElement = videoRef.current;

  if (!videoElement) {
    return;
  }

  const mediaStream = videoElement.srcObject as MediaStream | null;
  stopMediaTracks(mediaStream, trackType);
};

/**
 * Toggle a media stream's track's (audio or video) enabled state.
 * Optionally make it a one-way operation when specifying the setTo parameter (enable or disable).
 */
export const toggleMediaTrack = (
  mediaStream: MediaStream,
  trackType: "video" | "audio",
  setTo?: boolean,
  stopTrack = true
) => {
  const tracks = mediaStream[trackType === "audio" ? "getAudioTracks" : "getVideoTracks"]();

  if (tracks.length === 0) {
    logger.error(`[📹VideoChat] No ${trackType} tracks found in the media stream!`);
    return;
  }

  if (tracks.length > 1) {
    logger.warn(`[📹VideoChat] There are ${tracks.length} ${trackType} tracks found in the media stream!`);
  }

  const mainTrack = tracks[0];

  mainTrack.enabled = setTo !== undefined ? setTo : !mainTrack.enabled;

  if (stopTrack) {
    if (setTo === undefined || setTo) {
      mainTrack.stop();
    }
  }
};

/**
 * Toggle a video element's media track's (audio or video) enabled state.
 * Optionally make it a one-way operation when specifying the setTo parameter (enable or disable).
 */
export const toggleMediaTrackForVideo = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  trackType: "video" | "audio",
  setTo?: boolean,
  stopTrack = true
) => {
  if (!videoRef.current || !videoRef.current.srcObject) {
    logger.error("[📹VideoChat] No media stream found for the video element!");
    return;
  }

  const mediaStream = videoRef.current.srcObject as MediaStream;
  toggleMediaTrack(mediaStream, trackType, setTo, stopTrack);
};

export const countMediaTracksForVideo = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  trackType: "video" | "audio" | "all",
  onlyLive = false,
  onlyEnabled = false
): number => {
  if (!videoRef.current) return 0;

  if (trackType === "all") {
    const audioTracks = getMediaTracksForVideo(videoRef, "audio", onlyLive, onlyEnabled);
    const videoTracks = getMediaTracksForVideo(videoRef, "video", onlyLive, onlyEnabled);
    return videoTracks.length + audioTracks.length;
  }

  const tracks = getMediaTracksForVideo(videoRef, trackType, onlyLive, onlyEnabled);
  return tracks.length;
};

/**
 * Check if a media stream has an active track of a specific type.
 * @param stream - The media stream to check.
 * @param type - The type of track to check ("audio" or "video").
 * @param checkEnabled - Whether to check if the track is enabled. Defaults to true.
 * @param checkLive - Whether to check if the track is live. Defaults to true.
 * @param ignoreDummy - Whether to ignore dummy tracks. Defaults to true.
 * @returns A boolean indicating whether an active track exists.
 */
export const hasActiveTrack = (
  stream: MediaStream,
  type: "audio" | "video",
  checkEnabled = true,
  checkLive = true,
  ignoreDummy = true
): boolean =>
  stream.getTracks().some((track: MediaStreamTrack) => {
    const isTypeMatch = track.kind === type;
    const isEnabledMatch = checkEnabled ? track.enabled : true;
    const isLiveMatch = checkLive ? track.readyState === "live" : true;

    // Check if the track is a dummy track
    const isDummy = isDummyTrack(track);

    // If ignoreDummy is true, we exclude dummy tracks from being considered active
    const isDummyMatch = ignoreDummy ? !isDummy : true;

    return isTypeMatch && isEnabledMatch && isLiveMatch && isDummyMatch;
  });

/**
 * Check if an audio stream has an active live track.
 * @param stream - The media stream to check.
 * @param checkEnabled - Whether to check if the audio track is enabled.
 * @param checkLive - Whether to check if the audio track is live.
 * @param ignoreDummy - Whether to ignore dummy tracks.
 * @returns A boolean indicating whether the audio stream has an active live track.
 */
export const isAudioStreamLive = (
  stream?: MediaStream | null,
  checkEnabled = true,
  checkLive = true,
  ignoreDummy = true
): boolean => (stream ? hasActiveTrack(stream, "audio", checkEnabled, checkLive, ignoreDummy) : false);

/**
 * Check if a video stream has an active live track.
 * @param stream - The media stream to check.
 * @param checkEnabled - Whether to check if the video track is enabled.
 * @param checkLive - Whether to check if the video track is live.
 * @param ignoreDummy - Whether to ignore dummy tracks.
 * @returns A boolean indicating whether the video stream has an active live track.
 */
export const isVideoStreamLive = (
  stream?: MediaStream | null,
  checkEnabled = true,
  checkLive = true,
  ignoreDummy = true
): boolean => (stream ? hasActiveTrack(stream, "video", checkEnabled, checkLive, ignoreDummy) : false);

/**
 * Check if an audio stream has an active live track.
 * @param audioRef - The reference to the HTML audio element.
 * @returns A boolean indicating whether the audio stream has an active live track.
 */
export const isAudioLive = (audioRef: RefObject<HTMLAudioElement | null>): boolean => {
  const audio = audioRef.current;

  if (!audio) {
    return false;
  }

  const mediaStream = audio.srcObject as MediaStream | null;

  return isAudioStreamLive(mediaStream) && !audio.paused;
};

/**
 * Check if a video stream is live and the video element is playing.
 * @param videoRef - The reference to the HTML video element.
 * @returns A boolean indicating whether the video stream is live and the video is playing.
 */
export const isVideoLive = (videoRef: RefObject<HTMLVideoElement | null>): boolean => {
  const video = videoRef.current;

  if (!video) {
    return false;
  }

  const mediaStream = video.srcObject as MediaStream | null;

  return isVideoStreamLive(mediaStream) && !video.paused && video.readyState === 4;
};

export enum SoundEffect {
  JOIN_ROOM,
  LEAVE_ROOM,
}

type SoundEffectVariant = "default" | "alternative";

const soundEffects = {
  [SoundEffect.JOIN_ROOM]: {
    default: joinSoundEffectDefault,
    alternative: joinSoundEffectAlternative,
  },
  [SoundEffect.LEAVE_ROOM]: {
    default: leaveSoundEffectDefault,
    alternative: leaveSoundEffectAlternative,
  },
};

export const playSoundEffect = (
  soundEffectType: SoundEffect,
  variant: SoundEffectVariant = "alternative",
  volume = 0.1
) => {
  const soundEffect = soundEffects[soundEffectType]?.[variant];

  if (!soundEffect) {
    logger.error("Unknown sound effect:", soundEffectType, variant);
    return;
  }

  const audio = new Audio(soundEffect);
  audio.currentTime = 0; // Reset audio to start if already playing
  audio.volume = volume;
  void audio.play();
};

export const getDetailedPermissionState = (
  permission: PermissionState | "unknown",
  permissionError: boolean
): PermissionState | "denied-by-error" | "unknown" => {
  if (permission === "denied") {
    return "denied";
  }

  if (permissionError) {
    return "denied-by-error";
  }

  return permission;
};

export const getMicrophoneErrorString = (
  hasMicrophone: boolean | "unknown",
  micPermission: PermissionState | "denied-by-error" | "unknown"
) => {
  if (!hasMicrophone) {
    return strings.noMicrophoneDetected;
  }

  if (micPermission === "denied") {
    return strings.microphoneAccessDenied;
  }

  if (micPermission === "denied-by-error") {
    return strings.microphoneAccessDeniedByError;
  }

  return strings.microphoneWorks;
};

export const getWebcamErrorString = (
  hasCamera: boolean | "unknown",
  camPermission: PermissionState | "denied-by-error" | "unknown"
) => {
  if (!hasCamera) {
    return strings.noWebcamDetected;
  }

  if (camPermission === "denied") {
    return strings.webcamAccessDenied;
  }

  if (camPermission === "denied-by-error") {
    return strings.webcamAccessDeniedByError;
  }

  return strings.webcamWorks;
};

export const getFirstNavbarHeight = () =>
  getElementHeightById("doctor-navbar") ||
  getElementHeightById("owner-navbar") ||
  getElementHeightById("manager-navbar") ||
  56; // Fallback for computed height

export const getSecondNavbarHeight = () =>
  getElementHeightById("doctor-second-navbar") ||
  getElementHeightById("owner-second-navbar") ||
  getElementHeightById("manager-second-navbar") ||
  56; // Fallback for computed height

export const getFooterHeight = () =>
  getElementHeightById("footer") ||
  getElementHeightById("doctor-mobile-menu") ||
  getElementHeightById("owner-mobile-menu") ||
  getElementHeightById("manager-mobile-menu") ||
  61.875; // Fallback for computed height

export const getSectionHeight = () =>
  window.innerHeight - (getFirstNavbarHeight() + getSecondNavbarHeight() + getFooterHeight());

export const getVideosContainerHeight = () => {
  const sectionHeight = getSectionHeight();
  const callControlsHeight = getElementHeightById("call-controls") || 80; // Fallback for computed height

  return sectionHeight - callControlsHeight;
};

// [For debug] Display media information
export const displayMediaDebug = (props: MediaDebugProps): ReactElement => (
  <div className="absolute w-full h-full flex flex-wrap justify-start items-start content-baseline gap-1 pt-16 px-5 overflow-auto">
    {Object.entries(props).map(([key, value]) => (
      <p key={key} className="h-fit text-xs text-white bg-gray-500 bg-opacity-40 rounded-full p-2">
        {`${key}: ${value !== null && value !== undefined ? value.toString() : value}`}
      </p>
    ))}
  </div>
);

const getBooleanIcon = (enabled: boolean) =>
  enabled ? (
    <Tooltip content="Yes" placement="top">
      <p>✅</p>
    </Tooltip>
  ) : (
    <Tooltip content="No" placement="top">
      <p>❌</p>
    </Tooltip>
  );

const getPermissionIcon = (permissionState: string) => {
  switch (permissionState) {
    case "granted":
      return (
        <Tooltip content="Granted" placement="top">
          <p>✅</p>
        </Tooltip>
      );
    case "denied":
      return (
        <Tooltip content="Denied" placement="top">
          <p>❌</p>
        </Tooltip>
      );
    case "denied-by-error":
      return (
        <Tooltip content="Denied by error (temporarily denied)" placement="top">
          <p>🚫</p>
        </Tooltip>
      );
    case "prompt":
      return (
        <Tooltip content="Will be prompted on next access" placement="top">
          <p>💬</p>
        </Tooltip>
      );
    case "unknown": // for older browsers
      return (
        <Tooltip content="Unknown" placement="top">
          <p>❓</p>
        </Tooltip>
      );
    default:
      return (
        <Tooltip content="Unknown" placement="top">
          <p>❓</p>
        </Tooltip>
      );
  }
};

interface MediaStatus {
  enabled: boolean;
  hasDevice: boolean;
  hasError: boolean;
  permission: PermissionState | "denied-by-error" | "unknown";
}

// [For debug] Display media devices and permission state
export const createMediaStatusTable = (camStatus: MediaStatus, micStatus: MediaStatus) => (
  <table className="table table-auto">
    <thead>
      <tr>
        <th>Feature</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Microphone device</td>
        <td>{getBooleanIcon(micStatus.hasDevice)}</td>
      </tr>
      <tr>
        <td>Microphone permission</td>
        <td>{getPermissionIcon(micStatus.permission)}</td>
      </tr>
      <tr>
        <td>Microphone works</td>
        <td>{getBooleanIcon(micStatus.hasDevice && !micStatus.hasError)}</td>
      </tr>
      <tr>
        <td>Microphone enabled</td>
        <td>{getBooleanIcon(micStatus.enabled)}</td>
      </tr>
      <tr>
        <td>Camera device</td>
        <td>{getBooleanIcon(camStatus.hasDevice)}</td>
      </tr>
      <tr>
        <td>Camera permission</td>
        <td>{getPermissionIcon(camStatus.permission)}</td>
      </tr>
      <tr>
        <td>Camera works</td>
        <td>{getBooleanIcon(camStatus.hasDevice && !camStatus.hasError)}</td>
      </tr>
      <tr>
        <td>Camera enabled</td>
        <td>{getBooleanIcon(camStatus.enabled)}</td>
      </tr>
    </tbody>
  </table>
);

// [For debug] Logs details of a stream including their streams and tracks status (enabled & readyState).
export const logVideoStreamDetails = (stream?: MediaStream | null, videoId?: string) => {
  if (!stream) return;

  const trackDetails = stream.getTracks();

  if (trackDetails.length > 0) {
    trackDetails.forEach((track) => {
      let emoji = "❓";

      switch (track.kind) {
        case "audio":
          emoji = "🎤"; // Microphone
          break;
        case "video":
          emoji = "🎥"; // Camera
          break;
        default:
          break;
      }

      // Determine the enabled state icon
      const enabledIcon = track.enabled ? "✅" : "❌";

      logger.info(
        `${emoji} ${videoId ? `Video ID: ${videoId}, ` : ""}Stream: ${stream.id || "no-id"}, Track ID: ${
          track.id
        }, Type: ${track.kind}, ReadyState: ${track.readyState}, Enabled: ${enabledIcon}, Dummy: ${isDummyTrack(track)}`
      );
    });
  } else {
    logger.info(`Stream ID: ${stream.id || "no-id"} has no active tracks.`);
  }
};

// [For debug] Logs details of all video components, including their streams and tracks status (enabled & readyState).
// Useful for detecting media stream/track leaks.
export const logMediaStreamsOfAllVideoComponents = () => {
  console.log("[📹🪲VideoChat Debug] Media tracks of all video components:");

  const videoElements = document.querySelectorAll("video");

  videoElements.forEach((video) => {
    const mediaStream = video.srcObject as MediaStream;
    if (mediaStream) {
      logVideoStreamDetails(mediaStream, video.id);
    } else {
      logger.info(`Video ID: ${video.id || "no-id"} has no media stream.`);
    }
  });
};

export const logRtpSenderDetails = (peerConnection?: RTCPeerConnection) => {
  if (!peerConnection) return;

  console.log("[📹🪲VideoChat Debug] RTCRtpSender track details:");

  const senders = peerConnection.getSenders();

  if (senders.length === 0) {
    console.log("No RTCRtpSenders found in the peer connection.");
    return;
  }

  senders.forEach((sender, index) => {
    const track = sender.track;

    if (track) {
      let emoji = "❓";

      switch (track.kind) {
        case "audio":
          emoji = "🎤"; // Microphone
          break;
        case "video":
          emoji = "🎥"; // Camera
          break;
        default:
          break;
      }

      // Determine the enabled state icon
      const enabledIcon = track.enabled ? "✅" : "❌";

      console.log(
        `${emoji} Sender #${index + 1}, Track ID: ${track.id}, Type: ${track.kind}, ReadyState: ${
          track.readyState
        }, Enabled: ${enabledIcon}`
      );
    } else {
      console.log(`Sender #${index + 1} has no associated track.`);
    }
  });
};

export const logRtpReceiverDetails = (peerConnection?: RTCPeerConnection) => {
  if (!peerConnection) return;

  console.log("[📹🪲VideoChat Debug] RTCRtpReceiver track details:");

  const receivers = peerConnection.getReceivers();

  if (receivers.length === 0) {
    console.log("No RTCRtpReceivers found in the peer connection.");
    return;
  }

  receivers.forEach((receiver, index) => {
    const track = receiver.track;

    if (track) {
      let emoji = "❓";

      switch (track.kind) {
        case "audio":
          emoji = "🎤"; // Microphone
          break;
        case "video":
          emoji = "🎥"; // Camera
          break;
        default:
          break;
      }

      // Determine the enabled state icon
      const enabledIcon = track.enabled ? "✅" : "❌";

      console.log(
        `${emoji} Receiver #${index + 1}, Track ID: ${track.id}, Type: ${track.kind}, ReadyState: ${
          track.readyState
        }, Enabled: ${enabledIcon}`
      );
    } else {
      console.log(`Receiver #${index + 1} has no associated track.`);
    }
  });
};

// [For debug] List all available media devices
export const listMediaDevices = () => {
  navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      logger.info("[📹🪲VideoChat Debug] Available media devices:");
      devices.forEach((device) => {
        let emoji = "❓";

        switch (device.kind) {
          case "audioinput":
            emoji = "🎤"; // Microphone
            break;
          case "audiooutput":
            emoji = "🔈"; // Speaker
            break;
          case "videoinput":
            emoji = "🎥"; // Camera
            break;
          default:
            break;
        }

        // Use `❓` emoji for missing values
        const label = device.label || "❓";
        const deviceId = device.deviceId || "❓";
        const groupId = device.groupId || "❓";

        // Log the device details
        logger.info(`${emoji} Device ID: ${deviceId}, Label: ${label}, Group: ${groupId}, Kind: ${device.kind}`);
      });
    })
    .catch((error) => {
      logger.error("[📹🪲VideoChat Debug] Error enumerating media devices: ", error);
    });
};

/**
 * Formats the duration components into a human-readable string with localization.
 * @param years - The number of years.
 * @param months - The number of months.
 * @param days - The number of days.
 * @param hours - The number of hours.
 * @param minutes - The number of minutes.
 * @param seconds - The number of seconds.
 * @param maxUnits - The maximum number of units to display, from largest to smallest. Defaults to all.
 * @returns A formatted string representing the duration.
 */
const formatDurationString = (
  years: number,
  months: number,
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
  maxUnits = 6
): string => {
  const parts: string[] = [];

  if (years) {
    parts.push(`${years} ${years > 1 ? strings.yearPlural : strings.yearSingular}`);
  }
  if (months) {
    parts.push(`${months} ${months > 1 ? strings.monthPlural : strings.monthSingular}`);
  }
  if (days) {
    parts.push(`${days} ${days > 1 ? strings.dayPlural : strings.daySingular}`);
  }
  if (hours) {
    parts.push(`${hours} ${hours > 1 ? strings.hourPlural : strings.hourSingular}`);
  }
  if (minutes) {
    parts.push(`${minutes} ${minutes > 1 ? strings.minutePlural : strings.minuteSingular}`);
  }
  if (seconds) {
    parts.push(`${seconds} ${seconds > 1 ? strings.secondPlural : strings.secondSingular}`);
  }

  return parts.length ? parts.slice(0, maxUnits).join(", ") : `0 ${strings.secondPlural}`;
};

/**
 * Calculates the duration between a given date and the current moment.
 * @param date - The date object to compare with the current moment.
 * @returns A string representing the duration between the date and now.
 */
export const calculateDurationDate = (date: Date): string => {
  if (!moment(date).isValid()) {
    throw new Error("Invalid date provided");
  }

  const givenDate = moment(date);
  const now = moment();

  if (givenDate.isAfter(now)) {
    return "The given date is in the future";
  }

  const duration: Duration = moment.duration(now.diff(givenDate));
  return formatDurationString(
    duration.years(),
    duration.months(),
    duration.days(),
    duration.hours(),
    duration.minutes(),
    duration.seconds()
  );
};

/**
 * Converts a ConsultationTime object into a total number of seconds.
 *
 * @param {ConsultationTime} consultationTime - The ConsultationTime object to convert.
 * @returns {number} The total number of seconds.
 *
 * @example
 * const consultationTime = { hours: 1, minutes: 1, seconds: 5 };
 * const totalSeconds = calculateTotalSeconds(consultationTime);
 * console.log(totalSeconds); // Output: 3665
 */
export const calculateTotalSeconds = (consultationTime: ConsultationTime): number => {
  const { hours, minutes, seconds } = consultationTime;
  return hours * 3600 + minutes * 60 + seconds;
};

/**
 * Formats a call time given in seconds into a human-readable string.
 * @param totalSeconds - The total call time in seconds.
 * @returns A string representing the formatted call time.
 */
export const formatCallTimeSeconds = (totalSeconds: number): string => {
  const seconds = totalSeconds % 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return formatDurationString(0, 0, 0, hours, minutes, seconds);
};

/**
 * Converts a total number of seconds into a ConsultationTime object.
 *
 * @param {number} totalSeconds - The total number of seconds to convert.
 * @returns {ConsultationTime} An object containing the hours, minutes, and seconds.
 *
 * @example
 * const totalSeconds = 3665;
 * const consultationTime = createConsultationTime(totalSeconds);
 * console.log(consultationTime); // Output: { hours: 1, minutes: 1, seconds: 5 }
 */
export const calculateConsultationTime = (totalSeconds: number): ConsultationTime => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return {
    hours,
    minutes,
    seconds,
  };
};

/**
 * Checks if both a vet and a pet owner are present in the video chat room.
 *
 * This function examines the roles in both the local user and remote users to ensure
 * at least one user has the role of `VET` and one has the role of `PET_OWNER`.
 * If both roles are found among the local and connected users, it returns `true`;
 * otherwise, it returns `false`.
 *
 * @param {VideoChatUserConnection[]} remoteUsers - An array of VideoChatUserConnection objects representing users in the room.
 * @param {VideoConsultationRole[]} localUserRoles - An array of roles assigned to the local user.
 * @returns {boolean} - Returns `true` if both a vet and a pet owner are present, otherwise `false`.
 */
export const vetAndPetOwnerArePresent = (
  remoteUsers: VideoChatUserConnection[],
  localUserRoles: VideoConsultationRole[]
): boolean => {
  // Check if the local user has the required roles
  const localRoles = new Set(localUserRoles);
  const localVetPresent = localRoles.has(VideoConsultationRole.VET);
  const localPetOwnerPresent = localRoles.has(VideoConsultationRole.PET_OWNER);

  // If both roles are present locally, return true immediately
  if (localVetPresent && localPetOwnerPresent) return true;

  // Define helper function to check role presence among remote users
  const rolePresentInRemoteUsers = (role: VideoConsultationRole) =>
    remoteUsers.some((connection) => connection.user?.roles?.includes(role));

  // Determine if both roles are present either locally or remotely
  const vetPresent = localVetPresent || rolePresentInRemoteUsers(VideoConsultationRole.VET);
  const petOwnerPresent = localPetOwnerPresent || rolePresentInRemoteUsers(VideoConsultationRole.PET_OWNER);

  return vetPresent && petOwnerPresent;
};

/**
 * Calculates the total consultation price by rounding up the time to the next full unit (seconds, minutes, or occasions)
 * and multiplying it by the fee amount.
 *
 * @param lengthInSeconds - The duration of the consultation in seconds.
 * @param fee - The fee details including unit (SECONDS, MINUTES, OCCASIONS) and amount.
 * @return The total price as a number.
 */
export const calculateConsultationPrice = (lengthInSeconds: number, fee?: ClinicFeeResponse): number => {
  if (!fee || lengthInSeconds <= 0) {
    return 0;
  }

  switch (fee.unit) {
    case ClinicFeeUnit.MINUTES: {
      // Round up to the next minute
      const lengthInMinutes = Math.ceil(lengthInSeconds / 60);
      return fee.amount * lengthInMinutes;
    }

    case ClinicFeeUnit.SECONDS:
      // No rounding needed for seconds
      return fee.amount * lengthInSeconds;

    case ClinicFeeUnit.OCCASIONS:
      // Flat rate per occasion
      return fee.amount;

    default:
      return 0;
  }
};
