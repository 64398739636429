/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import AddressMapCheck from "../../../components/contact/address/AddressMapCheck";
import { PendingClinicRegistrationResponse } from "../../../models/clinic/PendingClinicRegistrationResponse";
import ClinicRegistrationApi from "../../../api/ClinicRegistrationApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { CountryResponse } from "../../../models/management/CountryResponse";
import { LocationRequest } from "../../../models/clinic/LocationRequest";

interface Props {
  countries: CountryResponse[];
  onPrevious(): void;
  registration?: PendingClinicRegistrationResponse;
  onNext(registration: PendingClinicRegistrationResponse): void;
  onError(error: string | null): void;
}

const LocationTab: React.FC<Props> = (props: Props) => {
  const { countries, registration, onNext, onError, onPrevious } = props;

  const submit = async (request: LocationRequest) => {
    if (!registration) return;
    onError(null);

    try {
      const response = await ClinicRegistrationApi.addLocation(
        registration.id,
        request
      );
      onNext(response.data);
    } catch (e) {
      onError(await getGeneralError(e));
    }
  };

  return (
    <AddressMapCheck
      address={registration?.addresses[0]}
      countryName={
        countries.find((c) => c.countryCode === registration?.countryCode)
          ?.displayName
      }
      changeAddress={() => {
        onPrevious();
      }}
      isMapCenterToCurrentLocation={false}
      setLocalizedAddress={submit}
    />
  );
};

export default LocationTab;
