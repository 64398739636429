/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import {
  canManageClinic,
  getGeneralError,
} from "../../../../util/helperFunctions";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import ClinicApi from "../../../../api/ClinicApi";
import CloseButton from "../../../../components/CloseButton";
import LoaderInline from "../../../../components/LoaderInline";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import { getClinicUpdateDto } from "../ClinicSettings";
import Button from "../../../../components/Button";
import Switch2 from "../../../../components/ReactHookFormFields/General/Switch2";
import Modal from "../../../../components/Modal/Modal";

interface Props {
  clinic: ClinicResponse;
  clinicId: string;
  setClinic: (modifiedClinic: ClinicResponse) => void;
}

const InventorySettingsTab: React.FC<Props> = ({
  clinic,
  clinicId,
  setClinic,
}: Props) => {
  const [alert, setAlert] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [savingInventoryHandling, setSavingInventoryHandling] =
    useState<boolean>(false);
  const [inventoryTrackingModalShow, setInventoryTrackingModalShow] =
    useState<boolean>(false);
  const [toggleSwitchBack, setToggleSwitchBack] = useState<boolean>(false);

  const { control, handleSubmit, setValue } = useForm<{
    inventoryTracking: boolean;
  }>({ defaultValues: { inventoryTracking: false }});

  useEffect(() => {
    if(clinic.inventoryStockTrackingEnabled){
      setValue("inventoryTracking", clinic.inventoryStockTrackingEnabled);
    }
  }, [clinic]);

  const saveInventoryTracking = async (isEnabled: boolean) => {
    const clinicDto = getClinicUpdateDto(clinic);
    clinicDto.inventoryStockTrackingEnabled = isEnabled;
    setSavingInventoryHandling(true);

    try {
      const resp = await ClinicApi.updateClinic(clinicId, clinicDto);
      const { data } = resp;
      if (data.inventoryStockTrackingEnabled) {
        setSuccess(strings.inventoryTrackingEnabled);
        setClinic({ ...clinic, inventoryStockTrackingEnabled: true });
      } else {
        setSuccess(strings.inventoryTrackingDisabled);
        setClinic({ ...clinic, inventoryStockTrackingEnabled: false });
      }
    } catch (err) {
      setAlert(await getGeneralError(err));
    } finally {
      setSavingInventoryHandling(false);
    }
  };

  const changeInventoryTracking = (value: boolean) => {
    if (value === clinic.inventoryStockTrackingEnabled) {
      return;
    }

    setSuccess(null);
    setAlert(null);

    if (!value) {
      setInventoryTrackingModalShow(true);
    } else if (value) {
      void saveInventoryTracking(value);
    }
  };

  const notToTurnOffTracking = () => {
    setInventoryTrackingModalShow(false);
    setValue("inventoryTracking", true);
    setToggleSwitchBack(true);
  };

  const turnOffTracking = () => {
    void saveInventoryTracking(false);
    setInventoryTrackingModalShow(false);
  };

  const readOnly = !canManageClinic(clinic);

  return (
    <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
      <div className="p-4">
        <p className="uppercase">{strings.inventorySettings}</p>
      </div>
      <div className="p-4">
        <div>{strings.inventoryToolTip}</div>
      </div>
      <form className="p-4" onSubmit={handleSubmit(turnOffTracking)}>
        <div className="flex">
          <div className="mr-3">
            <p>{`${strings.trackInventoryStock}:`}</p>
          </div>
          <div className="ml-auto">
            {savingInventoryHandling ? (
              <LoaderInline />
            ) : (
              <Switch2
                defaultChecked={clinic.inventoryStockTrackingEnabled || toggleSwitchBack}
                control={control}
                name="inventoryTracking"
                onChange={changeInventoryTracking}
                readOnly={readOnly}
              />
            )}
          </div>
        </div>
        <AlertBox message={alert} className="mt-4" />
        <AlertBox message={success} className="mt-4" type={AlertType.SUCCESS} />
        <Modal
          show={inventoryTrackingModalShow}
          handleClose={notToTurnOffTracking}
        >
          <Modal.Header title={strings.warning} closeButton />
          <Modal.Body>
            <AlertBox
              message={strings.inventoryTrackingDisableText}
              closeAble={false}
            />
          </Modal.Body>
          <Modal.Footer>
            <CloseButton onClick={notToTurnOffTracking} text={strings.decline} />
            <Button variant="primary" type="submit">
              {strings.accept}
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};

export default InventorySettingsTab;
