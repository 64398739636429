/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useBeforeUnload, useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { useForm } from "react-hook-form";
import { DataConnection, MediaConnection, Peer } from "peerjs";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  CallInfo,
  callUser,
  copyAddressToClipboard,
  createMediaStatusTable,
  createPeer,
  formatMediaConfig,
  getDetailedPermissionState,
  getFirstNavbarHeight,
  getFooterHeight,
  getMediaStreamProperties,
  getMicrophoneErrorString,
  getSecondNavbarHeight,
  getSectionHeight,
  getVideosContainerHeight,
  getWebcamErrorString,
  isAudioStreamLive,
  isVideoStreamLive,
  listMediaDevices,
  LocalConfig,
  logMediaStreamsOfAllVideoComponents,
  logRtpReceiverDetails,
  logRtpSenderDetails,
  logVideoStreamDetails,
  MediaConfig,
  MediaErrors,
  MediaFeatures,
  mediaStreamRegistry,
  playSoundEffect,
  recalculateLayout,
  RequestMedia,
  reserveCollectorCardSlot,
  setupUserVideo,
  SoundEffect,
  stopMediaTracksForVideo,
  toggleMediaTrackForVideo,
  usersToNameArray,
  vetAndPetOwnerArePresent,
  VideoChatUserConnection,
  VideoLayout,
} from "./Utils";
import Button from "../../components/Button";
import { strings } from "../../common/Strings/Strings";
import { VideoCamera } from "../../common/Icons/VideoCamera";
import { VideoCameraOff } from "../../common/Icons/VideoCameraOff";
import logger from "../../util/logger";
import { useUser } from "../../contexts/UserContext";
import { Card } from "../../components/Cards/Card";
import { callEndIcon, videoConsultationIcon } from "../../assets/AssetConfig";
import AlertBox, { AlertType } from "../../components/AlertBox";
import Switch from "../../components/ReactHookFormFields/General/Switch";
import { Microphone } from "../../common/Icons/Microphone";
import { LocalVideoPreview } from "./LocalVideoPreview";
import Params from "../../common/Params";
import VideoConsultationApi from "../../api/VideoConsultationApi";
import { VideoChatUser } from "../../models/videoConsultation/VideoChatUser";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import HorizontalLine from "../../components/HorizontalLine";
import LoaderInline from "../../components/LoaderInline";
import { MicrophoneOff } from "../../common/Icons/MicrophoneOff";
import Tooltip from "../../components/Tooltip";
import { ExclamationMark } from "../../common/Icons/ExclamationMark";
import CallControls from "./CallControls";
import { getGeneralError } from "../../util/helperFunctions";
import MediaErrorModal from "./MediaErrorModal";
import { getAccessToken } from "../../util/LocalStorageVariables";
import { VideoConsultationRole } from "../../models/videoConsultation/VideoConsultationRole";
import ConsultationStateInfo from "./ConsultationStateInfo";
import { SpinnerSize } from "../../common/Icons/Spinner";
import useElementDimensions from "../../hooks/useElementDimensions";
import { RoomEvent } from "../../models/videoConsultation/RoomEvent";
import { ConsultationStatus } from "../../models/videoConsultation/ConsultationStatus";
import { RoomEventType } from "../../models/videoConsultation/RoomEventType";
import { CheckCircle } from "../../common/Icons/CheckCircle";
import MedicalRecordButton from "./MedicalRecordButton";
import { VideoConsultationResponse } from "../../models/videoConsultation/VideoConsultationResponse";
import { UserPlus } from "../../common/Icons/UserPlus";
import {
  ConnectionsPayload,
  DataConnectionPayload,
  MediaConnectionPayload,
  remoteUsersReducer,
  StreamPayload,
} from "./remoteUsersReducer";
import useMediaPermissions from "../../hooks/useMediaPermissions";
import useTrackStateListener from "../../hooks/useTrackStateListener";
import { createDummyAudioTrack, createDummyVideoTrack, fillWithDummyTracks } from "./DummyStream";
import PermissionRequestModal from "./PermissionRequestModal";
import { TailwindResponsiveBreakpoints } from "../../util/TailwindResponsiveBreakpoints";
import VideosContainer from "./VideosContainer";

interface VideoConsultationPreparationForm {
  camEnabled: boolean;
  micEnabled: boolean;
}

// Configure the toggle modes for microphone and camera here.
// - "toggle": Simply enable/disable the track without stopping it.
//   Note: Browsers may still show a "Device is in use" message for disabled tracks.
// - "stop-restart": Fully stop the media track and reacquire it when toggling.
//   This ensures a clean release of resources, particularly for the camera.
type MediaToggleMode = "toggle" | "stop-restart";

// Microphone is in "toggle" mode, and the camera is in "stop-restart" mode (mimics Google Meet's behavior).
// **Reasoning**:
// - For the microphone: Avoids reloading the stream every time the user mutes/unmutes the microphone.
// - For the camera: Using "stop-restart" ensures proper resource cleanup.
const MICROPHONE_TOGGLE_MODE: MediaToggleMode = "toggle";
const CAMERA_TOGGLE_MODE: MediaToggleMode = "stop-restart";

// Debug flags (set these to true if needed during development)
const SIMULATE_MISSING_CAMERA = false; // If the camera detection can't be directly removed from the browser
const SIMULATE_MISSING_MICROPHONE = false; // If the microphone detection can't be directly removed from the browser
const ALLOW_WITHOUT_RESERVATION = false; // The video consultation waiting room won't throw an error without reservation

const VideoConsultation: React.FC = () => {
  const { user: localUser } = useUser();
  const [roles, setRoles] = useState<VideoConsultationRole[]>([]);

  const [videoConsultationInfo, setVideoConsultationInfo] = useState<VideoConsultationResponse>();

  const [debugMode] = useLocalStorage<boolean>("debugMode");
  const [testVideoCount, setTestVideoCount] = useState<number>(0);

  // Call state
  const { roomId } = useParams<{ roomId: string }>();

  // Peer for local user
  const peerRef = useRef<Peer>();

  // Remote user connections
  const [remoteUsers, dispatch] = useReducer(remoteUsersReducer, []);
  const remoteUsersRef = useRef<VideoChatUserConnection[]>([]);

  useEffect(() => {
    remoteUsersRef.current = remoteUsers;
  }, [remoteUsers]);

  const [usersInRoom, setUsersInRoom] = useState<VideoChatUser[]>([]);
  const [usersFetched, setUsersFetched] = useState<boolean>(false);

  const socketRef = useRef<Socket>();
  const streamRef = useRef<MediaStream | null>(null);

  // Ref for either the user view preview (before call started) or the user video (after call started)
  const videoRef = useRef<HTMLVideoElement | null>(null);

  // UI state
  const [inviteMessageVisible, setInviteMessageVisible] = useState(false);

  const [callStarted, setCallStarted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);

  // Synchronized state from the signal server
  const [consultationStatus, setConsultationStatus] = useState<ConsultationStatus | null>(null);
  const [payableCallTime, setPayableCallTime] = useState(0);
  const [finalPayableCallTime, setFinalPayableCallTime] = useState<number | null>(null);
  const timerRef: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef();

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [addTestMeetingLoading, setAddTestMeetingLoading] = useState<boolean>(false);
  const [addTestMeetingDone, setAddTestMeetingDone] = useState<boolean>(false);

  // Media devices enabled state
  const [micEnabled, setMicEnabled] = useLocalStorage<boolean>("micEnabled", true);
  const [camEnabled, setCamEnabled] = useLocalStorage<boolean>("camEnabled", false);
  const micEnabledRef = useRef<boolean>(micEnabled);
  const camEnabledRef = useRef<boolean>(camEnabled);

  // General errors when acquiring the media
  const [mediaErrors, setMediaErrors] = useState<MediaErrors>({
    micError: false,
    camError: false,
  });

  // Special errors for cases where the permission API is not fully implemented or a custom state is used
  // Example: "Temporarily blocked" state in Firefox
  const [mediaPermissionErrors, setMediaPermissionErrors] = useState<MediaErrors>({
    micError: false,
    camError: false,
  });

  // Media devices permissions
  const { hasMicrophone, micPermission, hasCamera, camPermission } = useMediaPermissions({
    simulateMissingCamera: SIMULATE_MISSING_CAMERA,
    simulateMissingMicrophone: SIMULATE_MISSING_MICROPHONE,
  });

  // Permissions and media devices state
  const mediaFeaturesRef = useRef<MediaFeatures>({
    hasMicrophone: false,
    hasMicrophonePermission: false,
    hasWebcam: false,
    hasWebcamPermission: false,
  });

  useEffect(() => {
    mediaFeaturesRef.current = {
      hasMicrophone: hasMicrophone === true,
      hasMicrophonePermission: !mediaPermissionErrors.micError && micPermission === "granted",
      hasWebcam: hasCamera === true,
      hasWebcamPermission: !mediaPermissionErrors.camError && camPermission === "granted",
    };
  }, [
    camPermission,
    hasCamera,
    hasMicrophone,
    mediaPermissionErrors.camError,
    mediaPermissionErrors.micError,
    micPermission,
  ]);

  const [showPermissionRequestModal, setShowPermissionRequestModal] = useState<boolean>(false);
  const [showMediaErrorModal, setShowMediaErrorModal] = useState<boolean>(false);

  const { ref: containerRef, dimensions: containerDimensions } = useElementDimensions(callStarted && !callEnded, true);

  const [layout, setLayout] = useState<VideoLayout>({
    area: 0,
    cols: 0,
    rows: 0,
    width: 0,
    height: 0,
    visibleVideos: 0,
    remainingVideos: 0,
  });

  const { control } = useForm<VideoConsultationPreparationForm>({
    mode: "onChange",
  });

  useEffect(() => {
    if (ALLOW_WITHOUT_RESERVATION) return;

    const validateVideoConsultation = async () => {
      if (!roomId) return; // Missing roomId should show the 404 page
      setError(null);
      setValidationLoading(true);

      try {
        const response = await VideoConsultationApi.getVideoConsultation(roomId);
        setVideoConsultationInfo(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setValidationLoading(false);
      }
    };

    void validateVideoConsultation();
  }, [roomId]);

  const getLocalUserConfig = useCallback(
    (localRoles?: VideoConsultationRole[]): VideoChatUser => {
      if (!socketRef.current?.id) {
        throw new Error("[📹VideoChat] Socket is not properly initialized!");
      }

      if (!peerRef.current?.id) {
        throw new Error("[📹VideoChat] Peer is not properly initialized!");
      }

      const mediaConfig: MediaConfig & MediaErrors = {
        camEnabled: camEnabledRef.current,
        micEnabled: micEnabledRef.current,
        camMissing: !hasCamera || camPermission !== "granted" || mediaPermissionErrors.camError,
        micMissing: !hasMicrophone || micPermission !== "granted" || mediaPermissionErrors.micError,
        camError: mediaErrors.camError,
        micError: mediaErrors.micError,
      };

      return {
        id: localUser.userId,
        mediaConfig,
        name: localUser.details.fullName,
        peerId: peerRef.current?.id,
        profilePictureId: localUser.profilePicture,
        roles: localRoles || roles,
        sessionId: socketRef.current.id,
      };
    },
    [
      camPermission,
      hasCamera,
      hasMicrophone,
      localUser.details.fullName,
      localUser.profilePicture,
      localUser.userId,
      mediaErrors.camError,
      mediaErrors.micError,
      mediaPermissionErrors.camError,
      mediaPermissionErrors.micError,
      micPermission,
      roles,
    ]
  );

  const updateLayout = useCallback(() => {
    // The number of videos equals to the number of remotePeer videos plus the local user video
    const layout = recalculateLayout(
      containerDimensions.width,
      containerDimensions.height,
      remoteUsers.length + (debugMode ? testVideoCount : 0) + 1,
      8, // gap-2 style on the videos-container
      8 // px-2 style on the videos-container
    );

    // Reserve a video slot for a collector card if needed
    const layoutWithCollectorCard = reserveCollectorCardSlot(layout);

    setLayout(layoutWithCollectorCard);
    if (debugMode) {
      logger.info("[📹VideoChat] Layout updated!");
    }
  }, [containerDimensions.height, containerDimensions.width, debugMode, remoteUsers.length, testVideoCount]);

  useLayoutEffect(() => {
    updateLayout();
  }, [updateLayout]);

  useEffect(() => {
    const handleResize = () => updateLayout();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateLayout]);

  const addUserMediaConnection = (payload: MediaConnectionPayload) => {
    dispatch({
      type: "ADD_USER_MEDIA_CONNECTION",
      payload,
    });

    console.log(`[📹VideoChat] Added media connection for peer with ID ${payload.mediaConnection.peer}`);
  };

  const addUserStream = (payload: StreamPayload) => {
    dispatch({
      type: "ADD_USER_STREAM",
      payload,
    });

    console.log(`[📹VideoChat] Added stream for peer with ID ${payload.peerId}`);
  };

  const addUserDataConnection = (payload: DataConnectionPayload) => {
    dispatch({
      type: "ADD_USER_DATA_CONNECTION",
      payload,
    });

    console.log(`[📹VideoChat] Added data connection for peer with ID ${payload.dataConnection.peer}`);
  };

  const addUserConnections = (payload: ConnectionsPayload) => {
    dispatch({
      type: "ADD_USER_CONNECTIONS",
      payload,
    });

    console.log(`[📹VideoChat] Added data and media connection for peer with ID ${payload.dataConnection.peer}`);
  };

  const removeRemoteUser = (id: string) => {
    dispatch({ type: "REMOVE_USER", payload: id });
  };

  const removeAllRemoteUsers = () => {
    dispatch({ type: "CLEAR_USERS" });
  };

  const joinRoom = useCallback(() => {
    if (!socketRef.current) {
      throw new Error("[📹VideoChat] No session ID found: The socket is not connected!");
    }

    // Join a video chat room
    const accessToken = getAccessToken();
    socketRef.current.emit("joinRoom", roomId, getLocalUserConfig(), accessToken);

    logger.info(`[📹VideoChat] Initiating join request to video consultation room with ID: ${roomId}.`);
  }, [getLocalUserConfig, roomId]);

  const disconnectFromRoom = useCallback((closeSocket = false) => {
    if (!socketRef.current) {
      throw new Error("[📹VideoChat] Disconnect error: Socket is missing!");
    }

    if (!timerRef) {
      throw new Error("[📹VideoChat] Disconnect error: Timer is missing!");
    }

    // Stop all the media tracks
    stopMediaTracksForVideo(videoRef);

    // Close all PeerJS connections
    remoteUsersRef.current.forEach((user) => {
      user.dataConnection?.close();
      user.mediaConnection?.close();
    });

    // Remove all users
    removeAllRemoteUsers();

    // Clear the timer
    clearInterval(timerRef.current);

    // Close the socket manually if required
    if (closeSocket) {
      socketRef.current?.disconnect();
    }

    // End the call and play the leave sound effect
    setCallEnded(true);
    playSoundEffect(SoundEffect.LEAVE_ROOM);
  }, []);

  // Replaces or adds new audio and video tracks to the peer connection from the provided stream.
  // Due to the limitations of PeerJS, adding/removing tracks does not work properly,
  // meaning it does not trigger PeerJS events for the receiver, so only the replaceTrack can be used.
  // Events of the underlying WebRTC API (like ontrack) are also limited, without renegotiation.
  // This also means that we have to use 2 tracks all the time on the remote side.
  // We also can't really detect track state or changes (only with the costly operation of disconnecting and recalling).
  // The remote video will always be shown as having two live and enabled tracks.
  // When media tracks are added, the dummy tracks are replaced with real tracks.
  // When media tracks are removed, they are replaced with dummy tracks (warning: don't replace with null track, that can modify sender count!)
  // On the receiver side, there are always 2 tracks, when replacing with dummy tracks, we will only disable the track
  // Warning: Don't stop the tracks on the receiver side! That again would require renegotiation to restart!
  const replaceStream = async (user: VideoChatUserConnection, stream?: MediaStream | null) => {
    const mediaConnection = user.mediaConnection;
    const dataConnection = user.dataConnection;

    if (!mediaConnection) {
      logger.error("[📹VideoChat] MediaConnection is missing!");
      return;
    }

    if (!dataConnection) {
      logger.error("[📹VideoChat] DataConnection is missing!");
      return;
    }

    const peerConnection = mediaConnection.peerConnection;
    if (!peerConnection) {
      logger.error("[📹VideoChat] No peerConnection found for this media connection!");
      return;
    }

    // Get the existing audio and video tracks from the stream
    const audioTracks = stream ? stream.getAudioTracks() : [];
    const videoTracks = stream ? stream.getVideoTracks() : [];

    // Get the transceivers of the peer connection
    const transceivers = peerConnection.getTransceivers();

    // Handle audio track replacement
    const audioSender = transceivers.find((t) => t.sender.track?.kind === "audio" || t.receiver.track?.kind === "audio")
      ?.sender;
    if (audioSender) {
      try {
        if (audioTracks[0]) {
          await audioSender.replaceTrack(audioTracks[0]);
        } else {
          await audioSender.replaceTrack(createDummyAudioTrack());
        }
      } catch (error) {
        logger.error("[📹VideoChat] Error replacing or removing audio track:", error);
      }
    } else {
      logger.error("[📹VideoChat] Audio sender is missing!");
    }

    // Handle video track replacement
    const videoSender = transceivers.find((t) => t.sender.track?.kind === "video" || t.receiver.track?.kind === "video")
      ?.sender;
    if (videoSender) {
      try {
        if (videoTracks[0]) {
          await videoSender.replaceTrack(videoTracks[0]);
        } else {
          await videoSender.replaceTrack(createDummyVideoTrack());
        }
      } catch (error) {
        logger.error("[📹VideoChat] Error replacing or removing video track:", error);
      }
    } else {
      logger.error("[📹VideoChat] Video sender is missing!");
    }
  };

  const setMediaError = useCallback(
    async (media: "audio" | "video" | "clearAll" | "clearForAudio" | "clearForVideo", error?: any) => {
      setError(null);

      switch (media) {
        case "audio":
          setMediaErrors((prevState) => ({ ...prevState, micError: true }));
          setMicEnabled(false);
          micEnabledRef.current = false;
          break;
        case "video":
          setMediaErrors((prevState) => ({ ...prevState, camError: true }));
          setCamEnabled(false);
          camEnabledRef.current = false;
          break;
        case "clearAll":
          setMediaErrors({ camError: false, micError: false });
          break;
        case "clearForAudio":
          setMediaErrors((prevState) => ({ ...prevState, micError: false }));
          break;
        case "clearForVideo":
          setMediaErrors((prevState) => ({ ...prevState, camError: false }));
          break;
        default:
          break;
      }

      if (error) {
        setError(await getGeneralError(error));
      }
    },
    [setMediaErrors, setMicEnabled, setCamEnabled]
  );

  const setMediaPermissionError = useCallback(
    async (media: "audio" | "video" | "clearAll" | "clearForAudio" | "clearForVideo", _error?: any) => {
      setError(null);

      switch (media) {
        case "audio":
          setMediaPermissionErrors((prevState) => ({ ...prevState, micError: true }));
          setMicEnabled(false);
          micEnabledRef.current = false;
          break;
        case "video":
          setMediaPermissionErrors((prevState) => ({ ...prevState, camError: true }));
          setCamEnabled(false);
          camEnabledRef.current = false;
          break;
        case "clearAll":
          setMediaPermissionErrors({ camError: false, micError: false });
          break;
        case "clearForAudio":
          setMediaPermissionErrors((prevState) => ({ ...prevState, micError: false }));
          break;
        case "clearForVideo":
          setMediaPermissionErrors((prevState) => ({ ...prevState, camError: false }));
          break;
        default:
          break;
      }
    },
    [setCamEnabled, setMicEnabled]
  );

  const replaceStreamForUser = useCallback(async (user: VideoChatUserConnection) => {
    // Replace the stream
    if (user.mediaConnection?.open) {
      await replaceStream(user, streamRef.current);
    }

    const dataToSend = JSON.stringify({
      camEnabled: camEnabledRef.current,
      micEnabled: micEnabledRef.current,
    });

    // Send the new media devices state
    // The microphone and camera states will be set up via ref when the connection is established
    // It's not necessary send these using the message buffer (which is implemented by default in peerJS) for these
    if (user.dataConnection?.open) {
      user.dataConnection.send(dataToSend);
    }
  }, []);

  const updateLocalUserVideoForRemoteUsers = useCallback(() => {
    // Send the new microphone and camera states to the remote peers
    remoteUsersRef.current.forEach((user: VideoChatUserConnection) => {
      void replaceStreamForUser(user);
    });
  }, [replaceStreamForUser]);

  const notifySignalServer = useCallback(() => {
    const config = getLocalUserConfig().mediaConfig;

    const newState = {
      camEnabled: camEnabledRef.current,
      micEnabled: micEnabledRef.current,
      ...config,
    };

    // Set the media state in the signal server (used by the monitor)
    socketRef.current?.emit("mediaDevices", newState);
  }, [getLocalUserConfig]);

  // Cleans up media locally and for the remote connections
  const cleanUpMedia = useCallback(async () => {
    stopMediaTracksForVideo(videoRef);
    mediaStreamRegistry.removeStream(streamRef.current);
    if (videoRef.current) videoRef.current.srcObject = null;
    streamRef.current = null;
  }, []);

  const updateLocalUserVideo = useCallback(
    async (requestMedia: "audio" | "video" | "all" | "none", retryWithSingleMedia = true) => {
      try {
        // Cleanup existing media
        await cleanUpMedia();

        // Reacquire media if needed
        if (requestMedia !== "none") {
          // Create media config
          const mediaConfig: MediaConfig = {
            camEnabled,
            micEnabled,
            camMissing: !hasCamera || camPermission !== "granted" || mediaPermissionErrors.camError,
            micMissing: !hasMicrophone || micPermission !== "granted" || mediaPermissionErrors.micError,
          };

          const stream = await setupUserVideo(
            videoRef,
            mediaFeaturesRef.current,
            mediaConfig,
            requestMedia,
            setMediaError,
            setMediaPermissionError,
            undefined,
            retryWithSingleMedia
          );

          if (stream) {
            streamRef.current = stream;
            logger.info("[📹VideoChat] Successfully attached media stream to the user video!");
          } else {
            logger.error("[📹VideoChat] Failed to acquire media stream.");
            setMicEnabled(false);
            setCamEnabled(false);
            micEnabledRef.current = false;
            camEnabledRef.current = false;
          }

          // Check live tracks for audio and video
          const audioTracksLive = isAudioStreamLive(streamRef.current);
          const videoTracksLive = isVideoStreamLive(streamRef.current);

          // Update state
          setMicEnabled(audioTracksLive);
          setCamEnabled(videoTracksLive);
          micEnabledRef.current = audioTracksLive;
          camEnabledRef.current = videoTracksLive;
        } else {
          logger.info("[📹VideoChat] No media requested. Cleaned up existing tracks.");
          setMicEnabled(false);
          setCamEnabled(false);
          micEnabledRef.current = false;
          camEnabledRef.current = false;
        }
      } catch (error) {
        logger.error("Error updating local user video:", error);
      }
    },
    [
      camEnabled,
      camPermission,
      cleanUpMedia,
      hasCamera,
      hasMicrophone,
      mediaPermissionErrors.camError,
      mediaPermissionErrors.micError,
      micEnabled,
      micPermission,
      setCamEnabled,
      setMediaError,
      setMediaPermissionError,
      setMicEnabled,
    ]
  );

  // Notifies remote users and the signal server about media devices state change if the call is active
  const notifyServerAndRemoteUsers = useCallback(() => {
    if (callStarted && !callEnded) {
      void updateLocalUserVideoForRemoteUsers();
      notifySignalServer();
    }
  }, [callEnded, callStarted, notifySignalServer, updateLocalUserVideoForRemoteUsers]);

  const [toggleLoading, setToggleLoading] = useState<"mic" | "cam" | false>(false);

  const toggleMedia = async (mediaType: "mic" | "cam", setTo?: boolean) => {
    setToggleLoading(mediaType);

    // Determine the toggle mode for the current media type
    const toggleMode = mediaType === "mic" ? MICROPHONE_TOGGLE_MODE : CAMERA_TOGGLE_MODE;

    // Check if tracks are live: if not, treat this as a `stop-restart` operation
    const audioTracksLive = isAudioStreamLive(streamRef.current, false, true, true);
    const videoTracksLive = isVideoStreamLive(streamRef.current, false, true, true);

    const requestMedia = (() => {
      const micOn = mediaType === "mic" ? setTo ?? !micEnabled : micEnabled;
      const camOn = mediaType === "cam" ? setTo ?? !camEnabled : camEnabled;

      if (micOn && camOn) return "all";
      if (micOn) return "audio";
      if (camOn) return "video";
      return "none";
    })();

    const isLiveTrackAvailableForMedia = mediaType === "mic" ? audioTracksLive : videoTracksLive;

    try {
      if (toggleMode === "toggle" && isLiveTrackAvailableForMedia) {
        // Directly toggle the media track using the helper function
        toggleMediaTrackForVideo(
          videoRef,
          mediaType === "mic" ? "audio" : "video",
          setTo,
          false // Do not stop the track in toggle mode
        );

        // Update the corresponding state
        if (mediaType === "mic") {
          micEnabledRef.current = setTo ?? !micEnabled;
          setMicEnabled(setTo ?? !micEnabled);
        } else {
          camEnabledRef.current = setTo ?? !camEnabled;
          setCamEnabled(setTo ?? !camEnabled);
        }
      } else {
        // For initial toggle or `stop-restart` mode, reacquire media
        logger.info(`[📹VideoChat] Reacquiring media: ${requestMedia}.`);
        await updateLocalUserVideo(requestMedia);
      }

      notifyServerAndRemoteUsers();
    } catch (error) {
      logger.error(`[📹VideoChat] Failed to toggle media (${mediaType}):`, error);
    } finally {
      setToggleLoading(false);
    }
  };

  // Dedicated toggle functions for mic and cam
  const toggleMic = async (setTo?: boolean) => toggleMedia("mic", setTo);
  const toggleCam = async (setTo?: boolean) => toggleMedia("cam", setTo);

  const [previewVideoIsInitialized, setPreviewVideoIsInitialized] = useState<boolean>(false);

  // This effect runs on the initial render
  // It initializes the preview video with using the initial values of the microphone and camera enabled states
  // The initial values are coming from the local storage with fallback initial values for first page visits
  useEffect(() => {
    const initPreviewVideo = async () => {
      if (previewVideoIsInitialized) return;
      if (
        hasMicrophone === "unknown" ||
        hasCamera === "unknown" ||
        micPermission === "unknown" ||
        camPermission === "unknown"
      ) {
        return;
      }

      const requestMedia = ((): RequestMedia | "none" => {
        if (micEnabled && camEnabled) return "all";
        if (micEnabled) return "audio";
        if (camEnabled) return "video";
        return "none";
      })();

      await updateLocalUserVideo(requestMedia, true);
      setPreviewVideoIsInitialized(true);
      logger.info("[📹VideoChat] Initialized the preview video.");
    };

    void initPreviewVideo();
  }, [
    camEnabled,
    camPermission,
    hasCamera,
    hasMicrophone,
    micEnabled,
    micPermission,
    previewVideoIsInitialized,
    updateLocalUserVideo,
  ]);

  useEffect(() => {
    if (
      micEnabled &&
      (hasMicrophone === false ||
        micPermission === "denied" ||
        micPermission === "prompt" ||
        mediaPermissionErrors.micError)
    ) {
      setMicEnabled(false);
      micEnabledRef.current = false;

      notifyServerAndRemoteUsers();
    }
  }, [
    hasMicrophone,
    mediaPermissionErrors.micError,
    micEnabled,
    micPermission,
    notifyServerAndRemoteUsers,
    setMicEnabled,
  ]);

  useEffect(() => {
    if (
      camEnabled &&
      (hasCamera === false ||
        camPermission === "denied" ||
        camPermission === "prompt" ||
        mediaPermissionErrors.camError)
    ) {
      setCamEnabled(false);
      camEnabledRef.current = false;

      notifyServerAndRemoteUsers();
    }
  }, [camEnabled, camPermission, hasCamera, mediaPermissionErrors.camError, notifyServerAndRemoteUsers, setCamEnabled]);

  // Detect the end of media tracks (e.g. when a permission block stops all the tracks)
  const handleTrackEnded = (trackType: "audio" | "video") => {
    // When the track ends, we can update the state to disable the corresponding media
    if (trackType === "audio") {
      setMicEnabled(false);
      micEnabledRef.current = false;
    } else if (trackType === "video") {
      setCamEnabled(false);
      camEnabledRef.current = false;
    }

    notifyServerAndRemoteUsers();
  };

  // Use the custom hook to listen for the "ended" event on tracks
  useTrackStateListener(videoRef, handleTrackEnded);

  // Creates a peer object for the local user
  const initPeerConnection = () => {
    peerRef.current = createPeer();

    peerRef.current.on("open", (id) => {
      // Start the call and play a sound effect
      setCallStarted(true);
      playSoundEffect(SoundEffect.JOIN_ROOM);
      console.log(`[📹VideoChat] Successfully connected to the signal server with ID '${id}'.`);
    });
  };

  // Sets up the answer... TODO: Outsource to Util
  const setupPeerCallbacks = () => {
    const peer = peerRef.current;

    if (!peer) {
      throw new Error("[📹VideoChat] Peer is missing!");
    }

    // Answer the call and add/update the user
    peer.on("call", (call: MediaConnection) => {
      // PeerJS limits the modification of media track count and state change detection of media tracks during call, so fill the stream with dummy tracks.
      // This way the remote stream will always have 2 live tracks.
      // We replace the tracks if needed, which is the only working solution to modify tracks during a call.
      call.answer(fillWithDummyTracks(streamRef));

      // Update the stream of the videoRef
      if (videoRef.current) {
        videoRef.current.srcObject = streamRef.current;
      }

      addUserMediaConnection({
        mediaConnection: call,
      });

      call.on("stream", (remoteStream: MediaStream) => {
        addUserStream({
          stream: remoteStream,
          peerId: call.peer,
        });
      });
    });

    // Send the local user's state to the caller user after successful data connection
    peer.on("connection", (connection: DataConnection) => {
      // Create media config
      const mediaConfig: MediaConfig = {
        camEnabled: camEnabledRef.current,
        micEnabled: micEnabledRef.current,
        camMissing: !hasCamera || camPermission !== "granted" || mediaPermissionErrors.camError,
        micMissing: !hasMicrophone || micPermission !== "granted" || mediaPermissionErrors.micError,
      };

      // Create the callee info
      const calleeInfo: CallInfo = {
        mediaConfig,
        user: getLocalUserConfig(),
      };

      // Send the local user's state to the caller user on successful data connection
      connection.on("open", () => {
        connection.send(JSON.stringify(calleeInfo));
      });

      // Process the remote user's state
      // The first time the callee (local user) receives data is instantly after a successful connection
      // Only handle the data once, the RemoteVideo component will handle subsequent data event (e.g. mic/cam toggle)
      connection.once("data", (data: any) => {
        const callInfo: CallInfo = JSON.parse(data.toString());

        addUserDataConnection({
          dataConnection: connection,
          user: callInfo.user,
          mediaConfig: callInfo.mediaConfig,
        });

        logger.info(`[📹VideoChat] ${callInfo.user.name} has joined the room.`);
      });
    });
  };

  const startTimer = (initialTime: number) => {
    setConsultationStatus(ConsultationStatus.IN_PROGRESS);
    setPayableCallTime(initialTime);

    timerRef.current = setInterval(() => {
      setPayableCallTime((prev) => prev + 1);
    }, 1000);
  };

  const pauseTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
    setConsultationStatus(ConsultationStatus.PAUSED);
  };

  const stopTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
    setConsultationStatus(ConsultationStatus.FINISHED);
  };

  const initSignalServerConnection = async () => {
    // Connect to the signal server
    socketRef.current = io(Params.videoChatSignalServiceBaseURL, {
      path: `${Params.videoChatPrefix}/socket.io`, // The handshake path includes the service prefix
    });

    // Listen for the 'connect' built-in event to ensure the connection is established
    socketRef.current.on("connect", () => {
      if (!socketRef.current || !socketRef.current.id) {
        throw new Error("[📹VideoChat] Socket is not properly initialized!");
      }

      // Initiate the peer connection to the PeerJS server
      initPeerConnection();

      // Set up the callback for answering the call
      setupPeerCallbacks();

      logger.info(`[📹VideoChat] Client created with ID: ${socketRef.current.id}.`);
    });

    // Listen for errors
    socketRef.current.on("error", (error) => {
      logger.error("[📹VideoChat] Socket.IO connection error:", error);
    });

    // Listen for the 'joinAnswer' event
    // This event is sent by the signal server when the local user joins a room (with possibly other users already in it)
    socketRef.current.on("joinAnswer", (answer: { otherUsers: VideoChatUser[]; roles: VideoConsultationRole[] }) => {
      if (!socketRef.current || !socketRef.current.id) {
        throw new Error("[📹VideoChat] No session ID found: The socket is not connected!");
      }

      // Set the role for the local user
      setRoles(answer.roles ?? []);

      // Create media config for peer config
      const mediaConfig: MediaConfig = {
        camEnabled: camEnabledRef.current,
        micEnabled: micEnabledRef.current,
        camMissing: !hasCamera || camPermission !== "granted" || mediaPermissionErrors.camError,
        micMissing: !hasMicrophone || micPermission !== "granted" || mediaPermissionErrors.micError,
      };

      // Create local user config
      const localUserConfig: LocalConfig = {
        mediaConfig,
        peerRef,
        streamRef,
        user: getLocalUserConfig(answer.roles),
      };

      logger.info(`[📹VideoChat] Local media config: ${formatMediaConfig(mediaConfig)}.`);

      // Call the users already in the room and call them
      answer.otherUsers.forEach((otherUser: VideoChatUser) => {
        callUser(otherUser, localUserConfig, videoRef, addUserConnections, addUserStream);
      });

      const userCount = answer.otherUsers.length;
      if (userCount === 0) {
        logger.info(`[📹VideoChat] Joined empty room.`);
      } else if (userCount === 1) {
        logger.info(`[📹VideoChat] Joined room to user ${answer.otherUsers[0].name}.`);
      } else {
        logger.info(`[📹VideoChat] Joined room with users ${usersToNameArray(answer.otherUsers)}.`);
      }
    });

    socketRef.current.on("newUserStatus", (status: ConsultationStatus, timestamp: number, payableCallTime: number) => {
      setConsultationStatus(status);

      const now = Date.now();
      const socketLatency = Math.floor((now - timestamp) / 1000);
      if (status === ConsultationStatus.IN_PROGRESS) {
        const latencyAdjustedStartTime = payableCallTime + socketLatency;
        startTimer(latencyAdjustedStartTime);
      } else {
        setPayableCallTime(payableCallTime);
      }
    });

    // Add the callEvent callback
    socketRef.current.on("callEvent", (event: RoomEvent) => {
      const now = Date.now();
      const socketLatency = Math.floor((now - event.timestamp) / 1000);

      switch (event.type) {
        case RoomEventType.CALL_STARTED: {
          const latencyAdjustedStartTime = (event.payload?.payableCallTime || 0) + socketLatency;
          startTimer(latencyAdjustedStartTime);
          break;
        }

        case RoomEventType.CALL_PAUSED: {
          pauseTimer();
          if (event.payload) {
            const latencyAdjustedPauseTime = event.payload.payableCallTime + socketLatency;
            setPayableCallTime(latencyAdjustedPauseTime);
          }
          break;
        }

        case RoomEventType.CALL_RESUMED: {
          if (event.payload) {
            const latencyAdjustedResumeTime = event.payload.payableCallTime + socketLatency;
            startTimer(latencyAdjustedResumeTime);
          }
          break;
        }

        case RoomEventType.CALL_FINISHED: {
          stopTimer();
          break;
        }

        default:
          console.warn("Unhandled event type:", event.type);
      }
    });

    // Add userLeft callback
    socketRef.current.on("userLeft", (id: string) => {
      const userToRemove = remoteUsersRef.current.find((u: VideoChatUserConnection) => u.user?.sessionId === id);

      const userName = userToRemove?.user?.name;

      if (userToRemove) {
        // Close connections
        userToRemove.dataConnection?.close();
        userToRemove.mediaConnection?.close();

        // Remove the user
        removeRemoteUser(userToRemove.id);
      }

      playSoundEffect(SoundEffect.LEAVE_ROOM);

      logger.info(`[📹VideoChat] ${userName} has left the room.`);
    });

    // Add consultationEnd callback
    socketRef.current.on("consultationEnd", (finalPayableCallTime: number) => {
      setFinalPayableCallTime(finalPayableCallTime);
      disconnectFromRoom(true);
      logger.info(`[📹VideoChat] The consultation has ended.`);
    });

    // Add disconnect callback
    socketRef.current.on("disconnect", (reason) => {
      logger.info(`[📹VideoChat] Disconnected from the room. Reason: ${reason}`);
    });
  };

  const handleJoinButtonClick = async () => {
    try {
      setError(null);
      await initSignalServerConnection();
    } catch (e) {
      logger.error(e);
      setError(await getGeneralError(e));
    }
  };

  useEffect(
    () =>
      // Cleanup function to disconnect from the room when the component unmounts
      () => {
        if (callStarted) {
          disconnectFromRoom(true);
        }
      },
    [callStarted, disconnectFromRoom]
  );

  const getIcon = (callEnded: boolean, consultationEnded: boolean) => (
    <div className="flex items-center justify-center">
      <div className="relative">
        <img src={videoConsultationIcon} className="w-28" alt={strings.videoConsultation} />
        {callEnded && (
          <div className="absolute bottom-2 -right-6">
            {consultationEnded ? (
              <CheckCircle className="w-12 h-12 text-green-600 dark:text-green-500" />
            ) : (
              <img src={callEndIcon} className="w-12" alt="Allow notifications" />
            )}
          </div>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    const getUsersInRoom = async () => {
      if (!roomId) return;

      try {
        const response = await VideoConsultationApi.getUsersInRoom(roomId);
        setUsersInRoom(response.data);
        setUsersFetched(true);
      } catch (e) {
        logger.error(e);
      }
    };

    // Poll every second
    const fetchUsersInterval = setInterval(getUsersInRoom, 1000);

    // Stop polling on call start
    if (callStarted) {
      clearInterval(fetchUsersInterval);
    }

    // Stop polling on component unmount
    return () => clearInterval(fetchUsersInterval);
  }, [callStarted, roomId]);

  // In debug mode run some debug functions
  useEffect(() => {
    if (!debugMode) {
      return undefined;
    }

    const intervalId = setInterval(() => {
      // Log stream ref
      logger.info("[📹🪲VideoChat Debug] StreamRef:");
      logger.info(getMediaStreamProperties(streamRef.current));

      // Log remote users
      logger.info("[📹🪲VideoChat Debug] Remote users:");
      logger.info(remoteUsers);

      // Log remote user connection tracks (outgoing)
      remoteUsersRef.current.forEach((user: VideoChatUserConnection) =>
        logRtpSenderDetails(user.mediaConnection?.peerConnection)
      );

      // Log remote user connection tracks (incoming)
      remoteUsersRef.current.forEach((user: VideoChatUserConnection) =>
        logRtpReceiverDetails(user.mediaConnection?.peerConnection)
      );

      // Logs local video stream and track details
      logMediaStreamsOfAllVideoComponents();

      // List streams
      logger.info("[📹🪲VideoChat Debug] Stream registry tracks:");
      mediaStreamRegistry.listStreams().forEach((stream: MediaStream) => logVideoStreamDetails(stream));

      // Logs media devices
      listMediaDevices();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [debugMode, remoteUsers]);

  // Control the number of test videos in debug mode
  const handleKeyDownDebug: KeyboardEventHandler<HTMLElement> = (e) => {
    if (!debugMode) {
      return;
    }

    // Add new test video
    if (e.key === "a") {
      setTestVideoCount((prevCount) => prevCount + 1);
    }

    // Delete a test video
    if (e.key === "d" && testVideoCount !== 0) {
      setTestVideoCount((prevCount) => prevCount - 1);
    }
  };

  const addToMedicalRecordTest = async () => {
    if (!roomId) return; // Missing roomId should show the 404 page

    setError(null);
    setAddTestMeetingLoading(true);
    setAddTestMeetingDone(false);

    try {
      await VideoConsultationApi.endCallTest(10, roomId);
      setAddTestMeetingDone(true);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setAddTestMeetingLoading(false);
    }
  };

  const callControls = (
    <CallControls
      camEnabled={camEnabled}
      camPermission={getDetailedPermissionState(camPermission, mediaPermissionErrors.camError)}
      consultationStatus={consultationStatus}
      disconnectFromRoom={() => disconnectFromRoom(true)}
      getLocalUserConfig={getLocalUserConfig}
      hasCamera={hasCamera === true}
      hasMicrophone={hasMicrophone === true}
      mediaErrors={mediaErrors}
      micEnabled={micEnabled}
      micPermission={getDetailedPermissionState(micPermission, mediaPermissionErrors.micError)}
      payableCallTime={payableCallTime}
      roles={roles}
      roomId={roomId}
      showMediaErrorModal={() => setShowMediaErrorModal(true)}
      showPermissionRequestModal={() => setShowPermissionRequestModal(true)}
      socketRef={socketRef}
      toggleCam={toggleCam}
      toggleLoading={toggleLoading}
      toggleMic={toggleMic}
      vetAndPetOwnerArePresent={vetAndPetOwnerArePresent(remoteUsers, roles)}
    />
  );

  // This hook runs whenever this component dismounts, e.g. when a navigation event happens
  useEffect(
    () => () => {
      if (callStarted) {
        disconnectFromRoom(true);
      }
    },
    [callStarted, disconnectFromRoom]
  );

  // This hook runs whenever the user closes the tab, refreshes the page, or closes the browser
  useBeforeUnload(() => {
    // Check if a call is currently active (to avoid unnecessary disconnections)
    if (callStarted) {
      disconnectFromRoom(true);
    }
  });

  const isMobile = window.innerWidth < TailwindResponsiveBreakpoints.md;
  const isFooterMenuVisible = window.innerWidth < TailwindResponsiveBreakpoints.xl;

  return (
    <>
      <main className="main-default" onKeyDown={(e) => handleKeyDownDebug(e)} tabIndex={-1}>
        <section
          className="relative"
          style={{
            height: callStarted && !callEnded ? getSectionHeight() : "",
            marginTop: (callStarted && !callEnded) || !isMobile ? getFirstNavbarHeight() : 0,
          }}
        >
          {!callEnded && (
            <>
              {!callStarted && (
                <div className="flex justify-center">
                  <Card
                    title={strings.videoConsultation}
                    type="simple"
                    marginClass=""
                    paddingClass="p-8"
                    style={{
                      marginTop: isFooterMenuVisible ? 0 : getSecondNavbarHeight(),
                      marginBottom: isFooterMenuVisible && !isMobile ? getFooterHeight() * 2 : getFooterHeight(),
                    }}
                  >
                    {getIcon(false, false)}

                    {debugMode &&
                      createMediaStatusTable(
                        {
                          enabled: camEnabled,
                          hasDevice: hasCamera === true,
                          hasError: mediaErrors.camError,
                          permission: getDetailedPermissionState(camPermission, mediaPermissionErrors.camError),
                        },
                        {
                          enabled: micEnabled,
                          hasDevice: hasMicrophone === true,
                          hasError: mediaErrors.micError,
                          permission: getDetailedPermissionState(micPermission, mediaPermissionErrors.micError),
                        }
                      )}
                    <div className="space-y-3">
                      <div className="flex">
                        {micEnabled ? (
                          <Microphone className="mr-3" variant="outline" />
                        ) : (
                          <MicrophoneOff className="mr-3" variant="outline" />
                        )}
                        <div className="flex flex-row gap-2">
                          <p>{strings.enableMicrophone}</p>
                          {toggleLoading === "mic" && <LoaderInline />}
                        </div>
                        {(!hasMicrophone || mediaPermissionErrors.micError || micPermission === "denied") && (
                          <Tooltip
                            content={getMicrophoneErrorString(
                              hasMicrophone,
                              getDetailedPermissionState(micPermission, mediaPermissionErrors.micError)
                            )}
                            placement="top"
                          >
                            <div
                              onClick={() => hasMicrophone && setShowPermissionRequestModal(true)}
                              role="button"
                              tabIndex={-1}
                            >
                              <ExclamationMark className="ml-1 text-orange-700 dark:text-orange-400" />
                            </div>
                          </Tooltip>
                        )}
                        {mediaErrors.micError && (
                          <Tooltip content={strings.mediaErrorTooltip} placement="top">
                            <div onClick={() => setShowMediaErrorModal(true)} role="button" tabIndex={-1}>
                              <ExclamationMark className="ml-1 text-red-700 dark:text-red-400" />
                            </div>
                          </Tooltip>
                        )}
                        <div className="ml-auto">
                          <Switch
                            control={control}
                            name="micEnabled"
                            onChange={toggleMic}
                            prioritizePropValue
                            readOnly={
                              !mediaFeaturesRef.current.hasMicrophone ||
                              micPermission === "denied" ||
                              toggleLoading !== false
                            }
                            value={micEnabled}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        {camEnabled ? (
                          <VideoCamera className="mr-3" variant="outline" />
                        ) : (
                          <VideoCameraOff className="mr-3" variant="outline" />
                        )}
                        <div className="flex flex-row gap-2">
                          <p>{strings.enableCamera}</p>
                          {toggleLoading === "cam" && <LoaderInline />}
                        </div>
                        {(!hasCamera || mediaPermissionErrors.camError || camPermission === "denied") && (
                          <Tooltip
                            content={getWebcamErrorString(
                              hasCamera,
                              getDetailedPermissionState(camPermission, mediaPermissionErrors.camError)
                            )}
                            placement="top"
                          >
                            <div
                              onClick={() => hasCamera && setShowPermissionRequestModal(true)}
                              role="button"
                              tabIndex={-1}
                            >
                              <ExclamationMark className="ml-1 text-orange-700 dark:text-orange-400" />
                            </div>
                          </Tooltip>
                        )}
                        {mediaErrors.camError && (
                          <Tooltip content={strings.mediaErrorTooltip} placement="top">
                            <div onClick={() => setShowMediaErrorModal(true)} role="button" tabIndex={-1}>
                              <ExclamationMark className="ml-1 text-red-700 dark:text-red-400" />
                            </div>
                          </Tooltip>
                        )}
                        <div className="ml-auto">
                          <Switch
                            control={control}
                            name="camEnabled"
                            onChange={toggleCam}
                            prioritizePropValue
                            readOnly={
                              !mediaFeaturesRef.current.hasWebcam ||
                              camPermission === "denied" ||
                              toggleLoading !== false
                            }
                            value={camEnabled}
                          />
                        </div>
                      </div>
                      {!callStarted && (
                        <LocalVideoPreview
                          show={
                            mediaFeaturesRef.current.hasWebcam &&
                            mediaFeaturesRef.current.hasWebcamPermission &&
                            camEnabled &&
                            !mediaErrors.camError
                          }
                          videoRef={videoRef}
                        />
                      )}
                    </div>

                    <AlertBox message={error} />

                    <Button disabled={validationLoading} onClick={handleJoinButtonClick} variant="primary">
                      {strings.joinConsultation}
                    </Button>

                    {debugMode && (
                      <Button
                        disabled={addTestMeetingLoading}
                        loading={addTestMeetingLoading}
                        onClick={addToMedicalRecordTest}
                        variant="primary"
                      >
                        {strings.addTestMeetingButtonText}
                      </Button>
                    )}

                    <AlertBox
                      hidden={!addTestMeetingDone}
                      message={strings.addTestMeetingSuccess}
                      type={AlertType.SUCCESS}
                    />

                    <HorizontalLine />

                    {!usersFetched && <LoaderInline />}
                    <div className="space-y-3" hidden={!usersFetched}>
                      {usersInRoom.length === 0 ? (
                        <div>{strings.waitingForOtherParticipants}</div>
                      ) : (
                        <>
                          <p>{strings.usersInTheRoom}</p>
                          {usersInRoom.map((u) => (
                            <div className="flex flex-row items-center" key={u.id}>
                              <div className="w-10 h-10 mr-3">
                                <UserProfilePicture userId={u.id} />
                              </div>
                              <p>{u.name}</p>
                            </div>
                          ))}
                        </>
                      )}
                    </div>

                    <AlertBox
                      hidden={!inviteMessageVisible}
                      message={strings.meetingLinkCopiedToClipboard}
                      type={AlertType.INFO}
                    />

                    <Button
                      fullWidth
                      onClick={() => {
                        copyAddressToClipboard();
                        setInviteMessageVisible(true);
                      }}
                      variant="secondary"
                    >
                      <UserPlus
                        className="w-6 h-6 mr-2 flex-shrink-0 text-zinc-800 dark:text-white"
                        variant="outline"
                      />
                      {strings.inviteToMeeting}
                    </Button>

                    <MedicalRecordButton minW={false} reservation={videoConsultationInfo?.reservation} />
                  </Card>
                </div>
              )}
              {/* Components during call */}
              {callStarted && (
                <div id="video-call-components" className="w-full h-full">
                  {roles.length === 0 && (
                    <div className="flex justify-center items-center mt-14 w-full h-full">
                      <LoaderInline
                        className="text-xl gap-4 flex-col"
                        size={SpinnerSize.VideoChat}
                        text={strings.connectingToVideoConsultationRoom}
                        textVisible
                      />
                    </div>
                  )}
                  <VideosContainer
                    callControls={callControls}
                    camEnabled={camEnabled}
                    containerRef={containerRef}
                    debugMode={debugMode}
                    getVideosContainerHeight={getVideosContainerHeight}
                    joinRoom={joinRoom}
                    layout={layout}
                    localUser={localUser}
                    mediaFeaturesRef={mediaFeaturesRef}
                    micEnabled={micEnabled}
                    remoteUsers={remoteUsers}
                    roles={roles}
                    streamRef={streamRef}
                    testVideoCount={testVideoCount}
                    toggleCam={toggleCam}
                    toggleMic={toggleMic}
                    videoRef={videoRef}
                  />
                  {/* Call controls */}
                  <div className="pt-3 pb-5" hidden={roles.length === 0} id="call-controls">
                    {callControls}
                    <ConsultationStateInfo
                      consultationStatus={consultationStatus}
                      payableCallTimeInSeconds={payableCallTime}
                      videoConsultationId={roomId}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          {callEnded && (
            <div className="flex justify-center">
              <Card
                title={strings.videoConsultation}
                type="simple"
                marginClass=""
                paddingClass="p-8"
                style={{
                  marginTop: isFooterMenuVisible ? 0 : getSecondNavbarHeight(),
                  marginBottom: isFooterMenuVisible && !isMobile ? getFooterHeight() * 2 : getFooterHeight(),
                }}
              >
                {getIcon(true, finalPayableCallTime !== null)}
                {finalPayableCallTime !== null ? (
                  <>
                    <AlertBox
                      closeAble={false}
                      message={strings.consultationSuccessfullyFinished}
                      type={AlertType.SUCCESS}
                    />
                    <ConsultationStateInfo
                      payableCallTimeInSeconds={finalPayableCallTime}
                      revised
                      videoConsultationId={roomId}
                    />
                  </>
                ) : (
                  <p>{strings.youHaveDisconnected}</p>
                )}
                <MedicalRecordButton minW={false} reservation={videoConsultationInfo?.reservation} variant="primary" />
                <Button onClick={() => window.location.reload()} variant="primary">
                  {strings.goBackToWaitingRoom}
                </Button>
              </Card>
            </div>
          )}
        </section>
      </main>
      <PermissionRequestModal show={showPermissionRequestModal} onHide={() => setShowPermissionRequestModal(false)} />
      <MediaErrorModal show={showMediaErrorModal} onHide={() => setShowMediaErrorModal(false)} />
    </>
  );
};

export default VideoConsultation;
