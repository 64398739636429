/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import { canManageClinic, getGeneralError } from "../../../../util/helperFunctions";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import { EmployeeResponse } from "../../../../models/user/EmployeeResponse";
import { ClinicEmploymentType } from "../../../../models/employment/ClinicEmploymentType";
import ClinicEmploymentApi from "../../../../api/ClinicEmploymentApi";
import DeleteRequestModal from "./Modals/DeleteRequestModal";
import AlertBox from "../../../../components/AlertBox";
import LoaderInline from "../../../../components/LoaderInline";
import Tooltip from "../../../../components/Tooltip";
import { PlusIcon } from "../../../../common/Icons/PlusIcon";
import Button from "../../../../components/Button";
import { Trash } from "../../../../common/Icons/Trash";
import { CheckCircle } from "../../../../common/Icons/CheckCircle";
import { ExclamationMark } from "../../../../common/Icons/ExclamationMark";
import DeleteCollaboratorModal from "./Modals/DeleteCollaboratorModal";
import AddCollaboratorModal from "./Modals/AddCollaboratorModal";

export const collaboratorGroupTitles: {
  [key in ClinicEmploymentType]: string;
} = {
  MANAGER: strings.managers,
  RECEPTIONIST: strings.receptionists,
  VET: strings.vets,
  ASSISTANT: strings.assistants,
  AUTHORIZED_ASSISTANT: strings.authorizedAssistants,
};

interface Props {
  clinicId: string;
  clinic: ClinicResponse;
  getClinic(): void;
  isActiveTab: boolean;
}

const CollaboratorsTab: React.FC<Props> = ({ clinicId, clinic, getClinic, isActiveTab }: Props) => {
  const [isBeingAdded, setIsBeingAdded] = useState<boolean>(false);
  const [isBeingDeleted, setIsBeingDeleted] = useState<boolean>(false);

  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeResponse>();
  const [selectedEmployeeType, setSelectedEmployeeType] = useState<ClinicEmploymentType>();

  const [error, setError] = useState<string | null>(null);

  const [showAddCollaboratorModal, setShowAddCollaboratorModal] = useState<boolean>(false);
  const [showDeleteRequestModal, setShowDeleteRequestModal] = useState<boolean>(false);
  const [showDeleteCollaboratorModal, setShowDeleteCollaboratorModal] = useState<boolean>(false);

  const openAddCollaboratorModal = () => {
    setShowAddCollaboratorModal(true);
  };

  const closeAddCollaboratorModal = () => {
    setShowAddCollaboratorModal(false);
    setError(null);
  };

  const readOnly = !canManageClinic(clinic);

  const employeesOfType = (employeeType: string): Array<EmployeeResponse> => {
    switch (employeeType) {
      case "MANAGER":
        return clinic.managers;
      case "VET":
        return clinic.vets;
      case "ASSISTANT":
        return clinic.assistants;
      case "AUTHORIZED_ASSISTANT":
        return clinic.authorizedAssistants;
      case "RECEPTIONIST":
        return clinic.receptionists;
      default:
        return [];
    }
  };

  const deleteEmployee = (employee: EmployeeResponse, employeeType: ClinicEmploymentType): void => {
    setIsBeingDeleted(true);
    setSelectedEmployee(employee);
    setSelectedEmployeeType(employeeType);
    setShowDeleteCollaboratorModal(true);
  };

  const closeSubstitutionModal = () => {
    setIsBeingDeleted(false);
    setShowDeleteCollaboratorModal(false);
  };

  const confirmRequestDeletion = (user: EmployeeResponse) => {
    setSelectedEmployee(user);
    setShowDeleteRequestModal(true);
  };

  const deleteRequest = async () => {
    if (!selectedEmployee?.request?.id) return;
    setShowDeleteRequestModal(false);
    setIsBeingDeleted(true);

    try {
      await ClinicEmploymentApi.deleteClinicEmploymentRequest(selectedEmployee.request.id);
      getClinic();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setIsBeingDeleted(false);
    }
  };

  return (
    <>
      <div className="w-full md:tw-card md:max-w-6xl space-y-6 md:p-8">
        <div>
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
            {strings.collaborators}
            <div className="ml-auto">
              <LoaderInline hidden={!isBeingAdded || !isBeingDeleted} />
            </div>
          </h2>
        </div>
        <AlertBox message={error} className="mb-3" />
        <div>{strings.collaboratorsToolTip}</div>
        <div>
          <Button disabled={isBeingAdded} variant="link" onClick={openAddCollaboratorModal} hidden={readOnly}>
            <div className="flex">
              <PlusIcon /> <span>{strings.addCollab}</span>
            </div>
          </Button>
        </div>
        <div className="tw-table-container">
          <table className="tw-table">
            <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
              <tr>
                <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.name}
                </th>
                <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.email}
                </th>
                <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.phone}
                </th>
                <th
                  hidden={readOnly}
                  className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  {strings.operations}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
              <tr className="bg-gray-50 dark:bg-gray-800">
                <td colSpan={5} className="px-4 py-2 text-sm font-bold whitespace-nowrap uppercase">
                  {strings.owner}
                </td>
              </tr>
              <tr>
                <td>
                  <div className="px-4 py-2 text-sm font-medium whitespace-nowrap">{clinic?.owner?.fullName}</div>
                </td>
                <td />
                <td hidden={readOnly} />
              </tr>

              {Object.keys(collaboratorGroupTitles).map((employmentType) => {
                const employees = employeesOfType(employmentType);
                const collaboratorGroupTitle = collaboratorGroupTitles[employmentType as ClinicEmploymentType];

                return (
                  <>
                    <tr className="bg-gray-50 dark:bg-gray-800">
                      <td colSpan={5} className="px-4 py-2 text-sm font-bold whitespace-nowrap uppercase">
                        {collaboratorGroupTitle}
                      </td>
                    </tr>
                    {employees.map((employee: EmployeeResponse) => (
                      <tr key={employee.userId}>
                        <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                          <div className="flex items-center">
                            <span>
                              {!employee.request ? (
                                <></>
                              ) : (
                                <Tooltip content={strings.pendingRequest} placement="right">
                                  <ExclamationMark className="text-orange-500 h-5 w-5 mr-2" />
                                </Tooltip>
                              )}
                            </span>
                            <span>
                              {employee.fullName || employee.email?.value || employee?.request?.requestedEmail}
                              <span hidden={!employee.deleted} className="ml-2 badge badge-danger">
                                {strings.deletedProfile}
                              </span>
                            </span>
                          </div>
                        </td>
                        <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">{employee?.email?.value}</td>
                        <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">{employee.phone?.value}</td>
                        <td hidden={readOnly} className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                          <div className="float-right">
                            <Button
                              className="tw-link"
                              type="button"
                              variant="icon"
                              onClick={() =>
                                employee.request
                                  ? confirmRequestDeletion(employee)
                                  : deleteEmployee(employee, employmentType as ClinicEmploymentType)
                              }
                              disabled={isBeingDeleted}
                            >
                              <Trash />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <Button disabled={isBeingAdded} variant="link" onClick={openAddCollaboratorModal} hidden={readOnly}>
            <div className="flex">
              <PlusIcon /> <span>{strings.addCollab}</span>
            </div>
          </Button>
        </div>
        <AddCollaboratorModal
          clinicId={clinicId}
          getClinic={getClinic}
          isActiveTab={isActiveTab}
          loading={isBeingAdded}
          onHide={closeAddCollaboratorModal}
          setLoading={setIsBeingAdded}
          setSelectedEmployeeType={setSelectedEmployeeType}
          show={showAddCollaboratorModal}
        />
        <DeleteRequestModal
          deleteRequest={deleteRequest}
          open={showDeleteRequestModal}
          onHide={() => setShowDeleteRequestModal(false)}
          selectedEmployee={selectedEmployee}
        />
        <DeleteCollaboratorModal
          employee={selectedEmployee}
          employeeType={selectedEmployeeType}
          onHide={closeSubstitutionModal}
          reload={getClinic}
          show={showDeleteCollaboratorModal}
        />
      </div>
    </>
  );
};

export default CollaboratorsTab;
