/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import { useClinic } from "../../contexts/ClinicContext";
import { getGeneralError } from "../../util/helperFunctions";
import NumberFormatter from "../../util/NumberFormatter";
import LoaderInline from "../../components/LoaderInline";
import { ArrowDownTray } from "../../common/Icons/ArrowDownTray";
import Button from "../../components/Button";
import { downloadInventoryStockValuesPdf, printInventoryStockValuesPdf } from "../../util/FileUtils";
import AlertBox from "../../components/AlertBox";
import Tooltip from "../../components/Tooltip";
import { Printer } from "../../common/Icons/Printer";
import InventoryStatisticsApi from "../../api/InventoryStatisticsApi";
import { StockValuesResponse } from "../../models/inventory/statistics/StockValuesResponse";
import DatePickerInput from "../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";

interface StockValueForm {
  date?: Date;
}

const InventoryStockValue = (): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [statistics, setStatistics] = useState<StockValuesResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [reloadStatistics, setReloadStatistics] = useState<boolean>(false);

  const filterFormMethods = useForm<StockValueForm>({
    mode: "onChange",
    defaultValues: {
      date: new Date(),
    },
  });
  const { getValues, control } = filterFormMethods;

  const { clinic } = useClinic();

  useEffect(() => {
    const getStatistics = async () => {
      if (!clinic) return;
      try {
        setLoading(true);
        const request = getValues();
        const response = await InventoryStatisticsApi.getStockValues(clinic.id, request.date);
        setError(null);
        setStatistics(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void getStatistics();
  }, [reloadStatistics, getValues, clinic]);

  const printOrDownload = async (isDownload: boolean) => {
    if (!clinic) return;
    setPdfLoading(true);
    try {
      {
        const date = getValues().date;
        isDownload
          ? await downloadInventoryStockValuesPdf(clinic.id, date)
          : await printInventoryStockValuesPdf(clinic.id, date, () => {});
      }
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPdfLoading(false);
    }
  };

  return (
    <div className="w-full md:max-w-lg space-y-4">
      <div className="flex">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {strings.inventoryStockValue}
        </h2>
        <div className="ml-auto mr-4">
          <Tooltip content={strings.downloadInventoryStockValues} placement="top">
            <Button className="tw-link" disabled={pdfLoading} onClick={() => printOrDownload(true)} variant="icon">
              <ArrowDownTray />
            </Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip content={strings.printInventoryStockValues} placement="top">
            <Button className="tw-link" disabled={pdfLoading} onClick={() => printOrDownload(false)} variant="icon">
              <Printer />
            </Button>
          </Tooltip>
        </div>
      </div>
      <FormProvider {...filterFormMethods}>
        <form>
          <div className="flex items-center space-x-4">
            <DatePickerInput
              control={control}
              label={strings.date}
              name="date"
              onChange={() => {
                setReloadStatistics(!reloadStatistics);
              }}
            />
          </div>
        </form>
      </FormProvider>
      <AlertBox message={error} />
      <div>
        {loading ? (
          <LoaderInline />
        ) : (
          <div className="tw-table-container">
            <table className="tw-table">
              <tbody className="tw-tbody">
                <tr>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
                    {strings.inventoryStockTotalAcquisitionNetPrice}
                  </td>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(statistics.totalAcquisitionNetPrice, statistics.currency)}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
                    {strings.inventoryStockTotalAcquisitionGrossPrice}
                  </td>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(statistics.totalAcquisitionGrossPrice, statistics.currency)}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
                    {strings.inventoryStockTotalSaleNetPrice}
                  </td>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(statistics.totalSellingNetPrice, statistics.currency)}
                  </td>
                </tr>
                <tr>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
                    {strings. inventoryStockTotalSaleGrossPrice}
                  </td>
                  <td className="px-3 py-2 text-sm font-medium whitespace-nowrap text-right">
                    {statistics &&
                      NumberFormatter.formatPrice(statistics.totalSellingGrossPrice, statistics.currency)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default InventoryStockValue;
