/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import DeleteClinicModal from "./DeleteClinicModal";
import Button from "../../../../../components/Button";

interface Props {
  clinic?: ClinicResponse;
  refresh: () => void;
}

const ClinicDeletionSection: React.FC<Props> = ({ clinic, refresh }) => {
  const [deletionModalShown, setDeletionModalShown] = useState(false);

  const showModal = () => setDeletionModalShown(true);
  const hideModal = () => setDeletionModalShown(false);

  return (
    <>
      <div>
        <Button
          className="text-left text-sm"
          onClick={showModal}
          variant="link-danger"
        >
          {strings.deleteClinic}
        </Button>
      </div>
      {clinic && (
        <DeleteClinicModal
          clinic={clinic}
          show={deletionModalShown}
          hide={hideModal}
        />
      )}
    </>
  );
};

export default ClinicDeletionSection;
